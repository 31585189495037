export const fileType = {
  Image: {
    text: '图片',
    value: 'IMAGE'
  },
  Video: {
    text: '视频',
    value: 'VIDEO'
  },
  Audio: {
    text: '音频',
    value: 'AUDIO'
  },
  ImageVideo: {
    text: '图片或者视频',
    value: 'IMAGEVIDEO'
  }
}

export const accepts = {
  Image: {
    text: '图片格式',
    value: '.png,.jpg,.jpeg,.svg,.gif,.tiff,.webp,.bmp'
  },
  Video: {
    text: '视频格式',
    value: '.mp4,.wmv,.mov,.flv,.avi,.webm,.mkv'
  },
  Audio: {
    text: '音频格式',
    value: '.mp3,.wma,.aac,.wav,.ape,.flac,.ogg'
  }
}
