<template>
  <div class="custom-tree">
    <a-button
      @click="add"
    >
      新增
    </a-button>
    <a-button
      @click="del"
    >
      删除
    </a-button>
    <a-tree
      :tree-data="gData"
      :checkedKeys="checkedKeys"
      :replaceFields="replaceFields"
      @dragenter="onDragEnter"
      @drop="onDrop"
      @check="onCheck"
      :class="[noTreeSwitcher ? 'no-switcher' : '', 'draggable-tree']"
      draggable
      block-node
      checkable
    />
  </div>
</template>

<script>
// TODO form reset value不生效
import qs from 'qs'
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    visualValue: {
      type: Object,
      default() {
        return {}
      }
    },
    visualKeys: {
      type: Array,
      default() {
        return []
      }
    },
    noTreeSwitcher: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const list = []

    this.value.forEach((item, index) => {
      const key = (item.key === undefined || item.key === null) ? index : item.key
      if (this.$store.state.isBigScreen) {
        list.push({
          ...item,
          key: key,
          programId: this.$store.state.programId,
          treeTitle: (item.title && item.title.value) ? item.title.value : ((item.text && item.text.value) ? item.text.value : `列表项-${index + 1}`)
        })
      } else {
        list.push({
          ...item,
          key: key,
          treeTitle: (item.title && item.title.value) ? item.title.value : ((item.text && item.text.value) ? item.text.value : `列表项-${index + 1}`)
        })
      }
    })

    return {
      gData: list,
      checkedKeys: [],
      replaceFields: {
        title: 'treeTitle'
      },
      isBigSrceen: this.$store.state.isBigScreen
    }
  },
  watch: {
    value(val = []) {
      const list = []

      val.forEach((item, index) => {
        const key = (item.key === undefined || item.key === null) ? index : item.key
        list.push({
          ...item,
          key: key,
          treeTitle: (item.title && item.title.value) ? item.title.value : ((item.text && item.text.value) ? item.text.value : `列表项-${index + 1}`)
        })
      })

      this.gData = list
    }
  },
  methods: {
    onDragEnter(info) {
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    onDrop(info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr)
          }
          if (item.children) {
            return loop(item.children, key, callback)
          }
        })
      }
      const data = [...this.gData]

      // Find dragObject
      let dragObj
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })
      if (!info.dropToGap) {
        return
        // Drop on the content 拖拽变为子树
        // loop(data, dropKey, (item) => {
        //   item.children = item.children || []
        //   // where to insert 示例添加到尾部，可以是随意位置
        //   item.children.push(dragObj)
        // })
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, (item) => {
          item.children = item.children || []
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.unshift(dragObj)
        })
      } else {
        let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } else {
          ar.splice(i + 1, 0, dragObj)
        }
      }
      this.gData = data

      this.$emit('change', this.gData)
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    add() {
      const componentData = this.$store.getters.getByVisualKeys(this.visualKeys)
      if (componentData.max && componentData.max <= this.gData.length) {
        this.$message.error('超出列表最大值')
        return
      }

      console.log('1')
      const template = qs.parse(location.search).template
      const programId = this.$store.state.programId
      const visualValueStr = JSON.stringify(this.visualValue)
      const visualValue = JSON.parse(visualValueStr.replace(/{index}/g, this.gData.length))
      console.log(visualValue, 'visualValue')
      visualValue.visualKeys = [...this.visualKeys, this.gData.length]

      if (this.isBigSrceen) {
        this.newTemplate = ''

        this.$store.commit('updateNavList', null)

        if (template === 'index') {
          this.newTemplate = 'module'
        } else if (template === 'module') {
          this.newTemplate = 'module_item'
        } else if (template === 'module_item') {
          this.newTemplate = 'module_item_desc'
        } else if (template === 'module_item_desc') {
          this.gData.push({
            ...visualValue,
            key: this.gData.length
          })
          this.$emit('change', this.gData)
          return
        }
        console.log('newTemplate', this.newTemplate)
        this.$axios.post(`/bigscreen/program/page/${programId}?parentUrl=${location.pathname}&template=${this.newTemplate}`).then((res) => {
          visualValue.link.value = res.data.url
          visualValue.title.value = res.data.name

          this.gData.push({
            ...visualValue,
            key: this.gData.length
          })

          console.log(this.$store.state.navList)
          this.$emit('change', this.gData)
          this.$message.success('新增成功')
          this.$store.commit('updateNavList', true)

          this.checkedKeys = []
        }).catch((err) => {
          console.log(err)
          this.$store.commit('updateNavList', true)
          this.$message.error('新增失败')
        })
      } else {
        this.gData.push({
          ...visualValue,
          key: this.gData.length
        })
        console.log(this.gData, 'this.gData')
        this.$emit('change', this.gData)
      }
    },
    del() {
      // 重新排序是为了从下往上删
      this.checkedKeys.sort((a, b) => {
        return b - a
      })

      if (this.gData.length - this.checkedKeys.length < 1) {
        this.$message.error('请至少保留一页')
        return
      }

      this.checkedKeys.forEach((item) => {
        this.$store.commit('updateNavList', null)
        const programId = this.$store.state.programId
        if (qs.parse(location.search).template !== 'module_item_desc' && this.isBigSrceen) {
          const newList = this.gData.splice(item, 1)
          this.$axios.delete(`/bigscreen/program/page/${programId}?url=${newList[0].link.value}`).then((res) => {
            // 删除完之后需要重新设置visualKeys
            this.updateVisualKeys(this.gData, this.visualKeys.filter(item => !isNaN(item)))

            this.$emit('change', this.gData)
            this.$store.commit('updateNavList', true)
          })
        } else {
          this.gData.splice(item, 1)
        }
      })

      this.updateVisualKeys(this.gData, this.visualKeys.filter(item => !isNaN(item)))
      this.$emit('change', this.gData)
      this.checkedKeys = []
    },
    updateVisualKeys(list, indexList = []) {
      list.forEach((item, index) => {
        const ids = [...indexList, index]
        item.key = index

        this.updateItemVisualKeys(item, ids)
      })
    },
    updateItemVisualKeys(item, ids) {
      Object.keys(item).forEach((key) => {
        if (key === 'visualKeys') {
          let tempIndex = 0
          item.visualKeys.forEach((visualKey, index) => {
            if (!isNaN(visualKey)) {
              item.visualKeys[index] = ids[tempIndex]
              tempIndex = tempIndex + 1
            }
          })
        } else if (typeof item[key] === 'object' && item[key] !== null) {
          this.updateItemVisualKeys(item[key], ids)
        }
      })
    }
  }
}
</script>

<style lang="less">
.no-switcher {
  .ant-tree-switcher {
    width: 0 !important;
  }
}

.custom-tree {
  .ant-tree-title {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% + 20px);
  }
}
</style>
