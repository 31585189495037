<template>
  <div class="m-visual-c">
    <!-- <template v-for="(plugItem, plugIndex) in list">
      <component
        v-show="!useIframe"
        :is="plugItem.item"
        :key="`component-${plugIndex}`"
        :reqData="plugItem.reqData"
        :mode="mode"
        :id="plugItem.id"
        :timestamp="timestamp"
        :cmsVisual="true"
        @visual="newHandleVisual"
        :query="query"
        :isPC="isPC"
      />
      <div :key="`block-${plugIndex}`" style="height: 20px" />
    </template> -->
    <component
      v-show="!useIframe"
      :is="plugItem.item"
      v-for="(plugItem, plugIndex) in list"
      :key="`component-${plugIndex}`"
      :reqData="plugItem.reqData"
      :mode="mode"
      :id="plugItem.id"
      :timestamp="timestamp"
      :cmsVisual="true"
      @visual="newHandleVisual"
      :query="query"
      :isPC="isPC"
    />
    <div v-show="visualVisible" class="m-visual-box">
      <!-- <div class="m-box-title">
        所属组件名称：{{ componentName }}
      </div> -->
      <div class="m-box-form">
        <a-form :form="form" @submit="handleSubmit" layout="vertical">
          <template v-if="Array.isArray(value)">
            <GfVisualComponent
              v-for="(item, index) in value"
              v-show="!item.hidden"
              :key="index"
              :value="item.value"
              @visualChange="visualComponentChange"
              @materialChange="visualMaterialChange"
              @clipChange="visualClipChange"
            />
          </template>
          <template v-else>
            <GfVisualComponent
              :value="value"
              @visualChange="visualComponentChange"
              @materialChange="visualMaterialChange"
              @clipChange="visualClipChange"
            />
          </template>
          <a-form-item>
            <a-button type="primary" html-type="submit" style="margin-right: 10px;">
              确定
            </a-button>
            <a-button v-if="value.type !== 'List'" @click="handleCancel">
              取消
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <Material :type="type" :isShow="isShow" :isPC="isPC" @isShowChange="MaterialIsShow" @MaterialReturn="MaterialReturnChange" />
    <ImageClip @cancel="imageClipCancel" :isShow="isShowClip" :info="clipInfo" @ClipReturn="ClipReturnChange" />
  </div>
</template>

<script>
import _ from 'lodash'
// import qs from 'qs'
import { addLeaveConfirm, sendMessage2Iframe } from '@/assets/js/util'
import Material from './Material'
import ImageClip from './ImageClip.vue'
import GfVisualComponent from './GfVisualComponent'
import { fileType } from '@/constants/fileType'

export default {
  components: {
    Material,
    GfVisualComponent,
    ImageClip
  },
  props: {
    mode: {
      type: String,
      default: ''
    },
    timestamp: {
      type: Number,
      default: -1
    },
    children: {
      type: Array,
      default() {
        return []
      }
    },
    query: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      list: this.children,
      activeIndex: null,
      type: fileType.Image.value,
      isShow: false,
      value: '',
      oldValue: '', // form reset value 在自定义组件中不生效，都生效则可以移除
      visualKeys: [],
      visualVisible: false,
      componentName: '',
      isShowClip: false,
      clipInfo: {
        url: ''
      }
    }
  },
  computed: {
    useIframe() {
      return this.$store.state.iframe
    }
  },
  watch: {
    children(val = []) {
      this.list = val
    },
    '$store.state.iframe': {
      handler: function () {
        this.visualVisible = false
        this.value = ''
      }
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  mounted() {
    addLeaveConfirm()

    window.addEventListener('message', (event) => {
      // console.log('frame tongxin')
      console.log(event.data, 'frame tongxin')
      try {
        if (typeof event.data !== 'string') {
          return
        }
        const data = JSON.parse(event.data)
        console.log('data', data, data.type)

        switch (data.type) {
          case 'visual':
            // this.handleVisual(data)
            this.newHandleVisual(data)
            break
          case 'updateByVisualKeys':
          case 'addComponent':
          case 'deleteComponentById':
            this.$store.commit(data.type, data)
            break
          case 'updatePrimaryColor':
          case 'updateTlt':
            this.$store.commit(data.type, data.data)
            break
          case 'changeComponentIndex':
            this.$store.commit(data.type, data.list)
            break
          case 'updateData':
            this.$store.commit('updateComponents', data.data.components)
            break
          default:
            break
        }
      } catch (error) {
        console.log(error)
      }
    }, false)
  },
  methods: {
    newHandleVisual({ keys }) {
      console.log(keys, 'keys')
      if (this.visualVisible) {
        this.handleCancel()
        // 点开状态下如果没保存直接撤回
        // this.form.resetFields()
        // this.clear()
      }

      let value
      if (this.query.h5) {
        // iframe h5
        // console.log(keys)
        window.parent.postMessage(JSON.stringify({
          keys,
          type: 'visual'
        }), '*')
        return
      }

      if (Array.isArray(keys[0])) {
        // 目前场景只支持一层，后续扩展
        const temp = []

        keys.forEach((key) => {
          const obj = {
            key: key[key.length - 1],
            value: _.cloneDeep(this.$store.getters.getByVisualKeys(key))
          }

          temp.push(obj)
          this.componentName = this.getComponentDesc(key[0])
        })

        value = temp
      } else {
        value = _.cloneDeep(this.$store.getters.getByVisualKeys(keys))
        this.componentName = this.getComponentDesc(keys[0])
      }

      if (Array.isArray(value)) {
        const list = []
        value.forEach((item) => {
          item.value = { ...item.value }

          if (item.value.type === 'Image' || item.value.type === 'ImageVideo') {
            if (!this.useIframe && item.key.indexOf('2x') === -1 && item.key.indexOf('3x_h5') === -1) {
              item.hidden = true
            } else if (this.useIframe && item.key.indexOf('2x_h5') !== -1) {
              item.hidden = true
            } else if (this.useIframe && item.key.indexOf('2x') === -1 && item.key.indexOf('3x_h5') === -1) {
              item.hidden = true
              // 处理h5页面与web页面共用一张图
            } else if (!this.useIframe && item.key.indexOf('2x_h5') >= 0) {
              item.hidden = false
              // 单独为园区入驻组件做处理
            }

            list.push({ ...item })
          } else {
            list.push({ ...item.value })
          }
        })

        this.oldValue = _.cloneDeep(list)
      } else if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value.value)) {
          // 列表
          const list = []
          value.value.forEach((item) => {
            list.push({ ...item })
          })

          this.oldValue = _.cloneDeep(list)
        } else {
          this.oldValue = _.cloneDeep(value)
        }
      }

      this.value = value
      this.visualKeys = keys
      this.visualVisible = true
      this.$store.commit('updateCurrentVisualKeys', keys)
    },
    handleVisual({ keys }) {
      if (this.visualVisible) {
        return
        // 点开状态下不支持打开新的编辑界面
        // this.form.resetFields()
        // this.clear()
      }

      if (this.query.h5) {
        // iframe h5
        // console.log(keys)
        window.parent.postMessage(JSON.stringify({
          keys,
          type: 'visual'
        }), '*')
        return
      }

      // const list = this.list
      let value

      if (Array.isArray(keys[0])) {
        // 目前场景只支持一层，后续扩展
        const temp = []

        keys.forEach((key) => {
          console.log(this.$store.getters.getByVisualKeys(key))

          const obj = {
            key: key[key.length - 1],
            value: _.cloneDeep(this.$store.getters.getByVisualKeys(key))
          }

          temp.push(obj)
          this.componentName = this.getComponentDesc(key[0])
        })

        value = temp
      } else {
        value = _.cloneDeep(this.$store.getters.getByVisualKeys(keys))
        this.componentName = this.getComponentDesc(keys[0])
      }

      if (Array.isArray(value)) {
        const list = []
        value.forEach((item) => {
          item.value = { ...item.value }

          if (item.value.type === 'Image' || item.value.type === 'ImageVideo') {
            if (!this.useIframe && item.key.indexOf('2x') === -1 && item.key.indexOf('3x_h5') === -1) {
              item.hidden = true
            } else if (this.useIframe && item.key.indexOf('3x') === -1) {
              item.hidden = true
            } else if (!this.useIframe && item.key.indexOf('2x_h5') >= 0) {
              item.hidden = false
              // 单独为园区入驻组件做处理
            }

            list.push({ ...item })
          } else {
            list.push({ ...item.value })
          }
        })

        this.oldValue = _.cloneDeep(list)
      } else if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value.value)) {
          // 列表
          const list = []
          value.value.forEach((item) => {
            list.push({ ...item })
          })

          this.oldValue = _.cloneDeep(list)
        } else {
          this.oldValue = _.cloneDeep(value)
        }
      }

      this.value = value
      this.visualKeys = keys
      this.visualVisible = true
    },
    visualComponentChange({ e, value }) {
      this.visualUpdate(value.visualKeys, e.target ? e.target.value : e)

      sendMessage2Iframe({
        type: 'updateByVisualKeys',
        visualKeys: value.visualKeys,
        data: e.target ? e.target.value : e
      })
    },
    visualMaterialChange({ type, isShow, index }) {
      console.log('Visual父组件收到', type, isShow, index)
      this.type = type
      this.isShow = isShow
      this.activeIndex = index
    },
    visualClipChange(value) {
      console.log('visualClipChange', value)
      let clipProportion = []
      if (value.title) {
        const temp = value.title.split('-')
        if (temp.length >= 1) {
          clipProportion = temp[1].split('*')
        }
      }
      let newClipInfo = {}
      if (value.value.indexOf('poster:') !== -1) {
        newClipInfo = {
          url: value.value.replace(/^poster:/, '')
        }
      } else {
        newClipInfo = {
          url: value.value
        }
      }
      if (value.clipData) {
        newClipInfo.clipData = JSON.parse(value.clipData.value)
      }
      if (clipProportion.length) {
        newClipInfo.width = Number(clipProportion[0])
        newClipInfo.height = Number(clipProportion[1])
      }
      this.clipInfo = newClipInfo
      this.isShowClip = true
    },
    imageClipCancel() {
      this.isShowClip = false
    },
    MaterialIsShow(isShow) {
      this.isShow = isShow
    },
    MaterialReturnChange(obj) {
      if (this.value.type === 'VideoImageList') {
        console.log(this.value.value[this.activeIndex])
        const newList = this.$store.getters.getByVisualKeys(this.value.visualKeys)
        if (!newList.value[this.activeIndex]) {
          debugger
          const newsParams = _.cloneDeep(this.value.value[0])
          newsParams.key = this.value.value.length
          newsParams.treeTitle = '列表项' + (this.value.value.length + 1)
          newsParams.audio.value = ''
          if (obj.type === fileType.Video.value) {
            newsParams.videoUrl.value = obj.url
            newsParams.poster.value = obj.coverImage
            newsParams.img_2x.value = ''
            newsParams.img.value = ''
            newsParams.time.value = obj.duration
          } else if (obj.type === fileType.Image.value) {
            newsParams.videoUrl.value = ''
            newsParams.poster.value = ''
            newsParams.img_2x.value = obj.url
            newsParams.img.value = obj.coverImage
            if (newsParams.time.value !== 30) {
              newsParams.time.value = 30
            }
          }
          const newValueList = _.cloneDeep(newList.value)
          newValueList.push(newsParams)
          this.visualComponentChange({ e: newValueList, value: newList })
          this.$store.commit('updateIsDataChange', true)
          this.$store.commit('updatePageId', newValueList.length - 1)
        } else if (this.type === fileType.ImageVideo.value && obj.type === fileType.Image.value) {
          const newsParams1 = _.cloneDeep(newList.value[this.activeIndex])
          const newValueList1 = _.cloneDeep(newList.value)
          newsParams1.videoUrl.value = ''
          newsParams1.poster.value = ''
          newsParams1.img_2x.value = obj.url
          newsParams1.img.value = obj.coverImage
          if (newsParams1.time.value > 60) {
            newsParams1.time.value = 30
          }
          newValueList1[this.activeIndex] = newsParams1
          this.visualComponentChange({ e: newValueList1, value: newList })
        } else if (this.type === fileType.ImageVideo.value && obj.type === fileType.Video.value) {
          const newsParams2 = _.cloneDeep(newList.value[this.activeIndex])
          const newValueList2 = _.cloneDeep(newList.value)
          newsParams2.videoUrl.value = obj.url
          newsParams2.poster.value = obj.coverImage
          newsParams2.img_2x.value = ''
          newsParams2.img.value = ''
          newsParams2.audio.value = ''
          newsParams2.time.value = obj.duration
          newValueList2[this.activeIndex] = newsParams2
          this.visualComponentChange({ e: newValueList2, value: newList })
        } else if (this.type === fileType.Audio.value) {
          const newsParams3 = _.cloneDeep(newList.value[this.activeIndex])
          const newValueList3 = _.cloneDeep(newList.value)
          newsParams3.audio.value = obj.url
          newsParams3.time.value = obj.duration
          newValueList3[this.activeIndex] = newsParams3
          this.visualComponentChange({ e: newValueList3, value: newList })
        }
      } else if (this.value.type === 'MutiBgImage') {
        this.value.value[0].bgImg_2x.value = obj.url
        this.value.value[0].bgImg.value = obj.coverImage
        this.visualComponentChange({ e: obj.coverImage, value: this.value.value[0].bgImg })
        this.visualComponentChange({ e: obj.url, value: this.value.value[0].bgImg_2x })
      } else if (Array.isArray(this.value)) {
        if (this.value[0].value.type === 'VideoImageGroup') {
          console.log(this.value[0].value.value[0])
          console.log(this.type)
          if (this.type === fileType.Audio.value) {
            this.value[0].value.value[0].textAudio.value = obj.url
            this.visualComponentChange({ e: obj.url, value: this.value[0].value.value[0].textAudio })
          } else if (this.type === fileType.ImageVideo.value && obj.type === fileType.Video.value) {
            this.value[0].value.value[0].videoUrl.value = obj.url
            this.value[0].value.value[0].poster.value = obj.coverImage
            this.value[0].value.value[0].img.value = ''
            this.value[0].value.value[0].img_2x.value = ''
            this.value[0].value.value[0].textAudio.value = ''
            this.visualComponentChange({ e: obj.url, value: this.value[0].value.value[0].videoUrl })
            this.visualComponentChange({ e: obj.coverImage, value: this.value[0].value.value[0].poster })
            this.visualComponentChange({ e: '', value: this.value[0].value.value[0].img })
            this.visualComponentChange({ e: '', value: this.value[0].value.value[0].img_2x })
            this.visualComponentChange({ e: '', value: this.value[0].value.value[0].textAudio })
          } else if (this.type === fileType.ImageVideo.value && obj.type === fileType.Image.value) {
            this.value[0].value.value[0].videoUrl.value = ''
            this.value[0].value.value[0].poster.value = ''
            this.value[0].value.value[0].img.value = obj.coverImage
            this.value[0].value.value[0].img_2x.value = obj.url
            this.visualComponentChange({ e: '', value: this.value[0].value.value[0].videoUrl })
            this.visualComponentChange({ e: '', value: this.value[0].value.value[0].poster })
            this.visualComponentChange({ e: obj.coverImage, value: this.value[0].value.value[0].img })
            this.visualComponentChange({ e: obj.url, value: this.value[0].value.value[0].img_2x })
          }
        } else {
          this.value.forEach((item) => {
            if (item.hidden && this.type === fileType.Image.value && item.value.type === 'Image') {
              item.value.value = obj.coverImage
              this.visualComponentChange({ e: obj.coverImage, value: item.value })
            } else if (!item.hidden && this.type === fileType.Image.value && item.value.type === 'Image') {
              item.value.value = obj.url
              this.visualComponentChange({ e: obj.url, value: item.value })
            } else if ((this.type === fileType.Audio.value && item.value.type === 'Audio') || (this.type === fileType.Video.value && item.value.type === 'Video')) {
              item.value.value = obj.url
              this.visualComponentChange({ e: obj.url, value: item.value })
            } else if (this.type === fileType.Image.value && item.value.type === 'Poster') {
              if (obj.url === 'poster:') {
                obj.url = ''
              }
              item.value.value = 'poster:' + obj.url
              this.visualComponentChange({ e: 'poster:' + obj.url, value: item.value })
            }
            if (item.value.clipData) {
              // console.log('dealClipData')
              const clipData = {
                originUrl: item.value.value
              }
              item.value.clipData.value = JSON.stringify(clipData)
              this.$store.commit('updateByVisualKeys', {
                visualKeys: item.value.visualKeys,
                data: item.value.value,
                type: 'clipData',
                clipData: clipData
              })
            }
          })
        }
      }
    },
    ClipReturnChange(clipData) {
      if (clipData.imageUrl) {
        const obj = {
          coverImage: clipData.imageUrl,
          url: clipData.imageUrl
        }
        this.MaterialReturnChange(obj)
        this.imageClipCancel()

        if (Array.isArray(this.value)) {
          this.value.forEach((item) => {
            item.value.clipData = {}
            item.value.clipData.value = JSON.stringify(clipData)
            if (item.hidden && this.type === fileType.Image.value && item.value.type === 'Image') {
              this.$store.commit('updateByVisualKeys', {
                visualKeys: item.value.visualKeys,
                data: item.value.value,
                type: 'clipData',
                clipData: clipData
              })
            } else if (!item.hidden && this.type === fileType.Image.value && item.value.type === 'Image') {
              this.$store.commit('updateByVisualKeys', {
                visualKeys: item.value.visualKeys,
                data: item.value.value,
                type: 'clipData',
                clipData: clipData
              })
            }
          })
          this.imageClipCancel()
        }

        return
      }
      if (Array.isArray(this.value)) {
        this.value.forEach((item) => {
          item.value.clipData = {}
          item.value.clipData.value = JSON.stringify(clipData)
          if (item.hidden && this.type === fileType.Image.value && item.value.type === 'Image') {
            // this.visualComponentChange({ e: item.value.value, value: item.value })
            this.$store.commit('updateByVisualKeys', {
              visualKeys: item.value.visualKeys,
              data: item.value.value,
              type: 'clipData',
              clipData: clipData
            })
          } else if (!item.hidden && this.type === fileType.Image.value && item.value.type === 'Image') {
            // this.visualComponentChange({ e: item.value.value, value: item.value })
            this.$store.commit('updateByVisualKeys', {
              visualKeys: item.value.visualKeys,
              data: item.value.value,
              type: 'clipData',
              clipData: clipData
            })
          }
        })
        this.imageClipCancel()
      }
    },
    visualUpdate(keys, value) {
      this.$store.commit('updateByVisualKeys', {
        visualKeys: keys,
        data: value
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.MaterialIsShow(false)
      // const value = this.value
      this.form.validateFields((err, values) => {
        if (!err) {
          this.clear()
          this.$store.commit('updateNavList', false)
          this.$store.commit('updateNavList', true)
        }
        this.$store.commit('updateCurrentVisualKeys', null)
        // console.log('12')
        // console.log(value.type)
        // if (value.type === 'List') {
        //   console.log('1')
        //   const template = qs.parse(location.search).template
        //   console.log(template)
        //   const parentUrl = qs.parse(location.search).parent
        //   const programId = this.$store.state.programId

        //   this.$store.commit('updateNavList', true)

        //   this.$axios.post(`/bigscreen/program/page/${programId}?parentUrl=${parentUrl}&template=${template}`).then((res) => {
        //     console.log(res)
        //     console.log(this.$store.state.navList)
        //     this.$store.commit('updateNavList', false)
        //     this.$message.success('新增成功')
        //   }).catch((err) => {
        //     console.log(err)
        //     this.$store.commit('updateNavList', false)
        //     this.$message.error('新增失败')
        //   })
        // }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.MaterialIsShow(false)

      let oldValue = this.oldValue
      if (Array.isArray(oldValue)) {
        const temp = []
        oldValue.forEach((item) => {
          const tempItem = { ...item }

          if (tempItem.oldValue) {
            tempItem.value.value = tempItem.oldValue
          }

          temp.push(tempItem)
        })

        oldValue = temp
      }

      // if (this.value.value !== oldValue.value) {
      this.visualUpdate(this.visualKeys, oldValue)
      // }
      this.$store.commit('updateCurrentVisualKeys', null)

      sendMessage2Iframe({
        type: 'updateByVisualKeys',
        visualKeys: this.visualKeys,
        data: oldValue
      })

      this.clear()
    },
    clear() {
      this.value = ''
      this.visualKeys = []
      this.visualVisible = false
    },
    normFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    getComponentDesc(componentId) {
      const component = this.$store.state.components.find(component => component.id === componentId)
      return this.$store.state.configs(`./${component.key}/config.json`)[component.key].desc
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';
.m-visual-c {
  position: relative;
}
.g-doc .card-spacing.z-phone {
  margin: auto!important;
  margin-top: 0px!important;
  margin-bottom: pxtorem(100)!important;
  padding-top: pxtorem(100)!important;
}
.g-doc .card-spacing.z-phone + .card-spacing.z-phone {
  margin-top: pxtorem(100)!important;
  padding-top: 0!important;
  margin-bottom: pxtorem(100)!important;
}
.g-doc .card-spacing.z-web {
  margin: auto!important;
  margin-top: 0px!important;
  margin-bottom: 90px!important;
  padding-top: 90px!important;
}
.g-doc .card-spacing.z-web + .card-spacing.z-web {
  margin-top: 90px!important;
  padding-top: 0!important;
  margin-bottom: 90!important;
}

.m-visual-box {
  position: fixed;
  width: 240px;
  top: 61px;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 998;
  overflow-y: auto;
  box-shadow: -2px 2px 14px 0 rgba(0, 0, 0, 0.05);

  .m-box-title {
    line-height: 60px;
    font-size: 15px;
    color: #2e384d;
    padding: 0 20px;
    border-bottom: 1px solid #e4e8f0;
  }

  .m-box-form {
    padding: 20px 20px 0;
  }
}
</style>

<style lang="less">
// .m-visual-box {
//   .ant-input {
//     height: 38px;
//     background-color: #f6f7f9;
//     border: none;
//     outline: 0;
//     color: rgba(0, 0, 0, 0.85);
//     transition: all .2s ease-out;
//     &:focus {
//       box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
//       background-color: #f8f9fb;
//     }
//     &:hover {
//       background-color: #f8f9fb;
//     }
//   }
// }
</style>
