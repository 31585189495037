import { isNumber } from './is'
export function getTransforms({
  rotate,
  scaleX,
  scaleY,
  translateX,
  translateY
}) {
  const values = []

  if (isNumber(translateX) && translateX !== 0) {
    values.push(`translateX(${translateX}px)`)
  }

  if (isNumber(translateY) && translateY !== 0) {
    values.push(`translateY(${translateY}px)`)
  }

  // Rotate should come first before scale to match orientation transform
  if (isNumber(rotate) && rotate !== 0) {
    values.push(`rotate(${rotate}deg)`)
  }

  if (isNumber(scaleX) && scaleX !== 1) {
    values.push(`scaleX(${scaleX})`)
  }

  if (isNumber(scaleY) && scaleY !== 1) {
    values.push(`scaleY(${scaleY})`)
  }

  const transform = values.length ? values.join(' ') : 'none'
  console.log(transform, 'transform')
  return {
    WebkitTransform: transform,
    msTransform: transform,
    transform
  }
}
