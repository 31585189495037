<template>
  <div>
    <div class="m-title">
      <div class="u-title font-weight600">
        {{ screenProgram.subtype === 'ORDINARY' ? '设置' : '屏保设置' }}
      </div>
      <a-tooltip v-if="screenProgram.subtype !== 'ORDINARY'" placement="bottomRight">
        <template slot="title">
          当大屏5分钟没有任何有效操作时自动进入屏保
        </template>
        <div class="u-tooltip">
          <a-icon type="info-circle" />
          显示规则
        </div>
      </a-tooltip>
    </div>
    <a-divider class="u-line" />
    <div v-if="list.length > 0" class="u-tip">
      可通过拖拽调整内容播放顺序
    </div>
    <draggable
      v-model="list"
      @change="sortChange"
    >
      <div v-for="(item, index) in list" :key="index">
        <div class="u-video">
          <div v-if="item.videoUrl.value !== ''" @click="onShow(index)" class="m-block-box">
            <CMSVideo :videoUrl="item.videoUrl.value" :id="index" :class="['m-block-video', activeIndex === index? 'u-new': '']" :controls="false" :poster="item.poster.value" />
            <div @click="changeVideoImage(fileType.ImageVideo.value, index, 'video')" class="replace">
              <img src="../../static/GuoFang/replace.svg" alt="">
            </div>
            <div @click="onDelete('video', index)" class="delete">
              <img src="../../static/GuoFang/delete.svg" alt="">
            </div>
          </div>
          <div v-else class="m-block">
            <div class="u-mask" />
            <div @click="onShow(index)" :id="index" :class="['u-img-box', activeIndex === index? 'u-new': '']">
              <CMSImageBg :img="item.img.value" class="u-img">
                <div @click="changeVideoImage(fileType.ImageVideo.value, index)" class="replace">
                  <img src="../../static/GuoFang/replace.svg" alt="">
                </div>
                <!-- <div @click="toClip(item)" class="replace clip">
                  <img src="../../static/GuoFang/clip.svg" alt="">
                </div> -->
                <div @click="onDelete('img', index)" class="delete">
                  <img src="../../static/GuoFang/delete.svg" alt="">
                </div>
              </CMSImageBg>
            </div>
            <div class="m-set">
              <div v-if="item.audio.value !== ''" class="u-voice">
                <img src="../../static/GuoFang/voice.png">
                <div @click="onDelete('audio', index)" class="delete">
                  <img src="../../static/GuoFang/delete.svg" alt="">
                </div>
              </div>
              <div v-else class="u-voice">
                <img @click="changeVideoImage(fileType.Audio.value, index)" src="../../static/GuoFang/upload.svg" style="margin-top: 5px;">

                <a-popover>
                  <template slot="content" v-if="timeList[index]" overlayClassName="m-pop">
                    <a-row class="m-pop">
                      <a-col :span="8">
                        <span class="u-time-text">停留时间</span>
                      </a-col>
                    </a-row>
                    <a-row class="m-pop">
                      <a-col :span="18">
                        <a-slider
                          :defaultValue="item.time.value"
                          v-model="timeList[index].time.value"
                          :min="1"
                          :max="60"
                          @afterChange="sliderChange($event, index)"
                        />
                      </a-col>
                      <a-col :span="3">
                        <a-input-number
                          :defaultValue="item.time.value"
                          v-model="timeList[index].time.value"
                          :min="1"
                          :max="60"
                          :precision="0"
                          @change="numberChange($event, index)"
                          style="width: 57px"
                        />
                        <!-- <span class="u-time-text" style="margin-left: 5px;">{{ item.time.value }}s</span> -->
                      </a-col>
                    </a-row>
                  </template>
                  <div v-if="item.audio.value === ''" class="u-time">
                    <img src="../../static/GuoFang/time.png">
                    <div v-if="item.time.value !== ''">
                      {{ item.time.value }}s
                    </div>
                    <span v-else>30s</span>
                  </div>
                </a-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import _ from 'lodash'
import CMSImageBg from './ImageBg'
import draggable from 'vuedraggable'
import { fileType } from '@/constants/fileType'
import CMSVideo from '~/components/common/Video'

export default {
   components: {
    CMSImageBg,
    draggable,
    CMSVideo
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    visualKey: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      fileType,
      activeIndex: 0,
      newList: [],
      timeList: []
    }
  },
  computed: {
    list: {
       // getter
      get: function () {
        return this.$store.getters.getByVisualKeys(this.visualKey).value
      },
      // setter
      set: function (newValue) {
        this.$emit('change', newValue)
      }
    },
    screenProgram() {
      return this.$store.state.screenProgram
    }
  },
  watch: {
    // list: {
    //   deep: true,
    //   handler: function (val = '') {
    //     if (val) {
    //       console.log('val---', val)
    //     }
    //   }
    // }
    '$store.state.pageId': {
      deep: true,
      handler: function (val) {
        this.activeIndex = val
      }
    },
    '$store.state.isDataChange': {
      handler: function (val) {
        if (val) {
          this.$store.commit('updateIsDataChange', false)
          this.timeList = _.cloneDeep(this.list)
        }
      }
    }
  },
  mounted() {
    this.timeList = _.cloneDeep(this.list)
  },
  methods: {
    sortChange(event) {
      console.log('change', event, this.list)
      this.$emit('change', this.list)
      this.timeList = _.cloneDeep(this.list)
      // this.$store.commit('updateNavList', true)
    },
    changeVideoImage(type, index, typeDetail) {
      if (typeDetail === 'video') {
        this.timeList[index].time.value = 30
        // this.$emit('change', this.timeList)
      }
      this.$emit('changeStatus', { type, index, isShow: true })
    },
    onDelete(type, index) {
      window.event.stopPropagation()
      if (type === 'img' || type === 'video') {
        if (this.list.length < 2) {
          this.$message.error('请至少保留一张屏保！')
          return
        }
        const newDelList = _.cloneDeep(this.list)
        if (this.activeIndex >= index) {
          this.activeIndex--
          this.$store.commit('updatePageId', this.activeIndex)
        }
        newDelList.splice(index, 1)
        console.log(newDelList)
        this.$emit('change', newDelList)
      } else {
        const newList = _.cloneDeep(this.list)
        newList[index].audio.value = ''
        newList[index].time.value = 30
        this.$emit('change', newList)
      }
    },
    sliderChange(e, index) {
      this.timeList[index].time.value = e
      const newList = _.cloneDeep(this.list)
      newList[index].time.value = e
      this.$emit('change', newList)
    },
    onShow(index) {
      this.activeIndex = index
      this.$store.commit('updatePageId', index)
    },
    numberChange(value, index) {
      this.timeList[index].time.value = value
      const newList = _.cloneDeep(this.list)
      newList[index].time.value = value
      this.$emit('change', newList)
    },
    toClip(value) {
      console.log(value, 'visualScreensaver')
      this.$emit('clipChange', value)
    }
  }
}
</script>

<style lang="postcss">
.m-title {
  display: inline-block;
  width: 100%;

  .u-title {
    display: inline-block;
    font-size: 14px;
    color: #333;
  }

  .u-tooltip {
    display: inline-block;
    color: #999;
    font-size: 12px;
    float: right;
    cursor: pointer;
  }

  .font-weight600 {
    font-weight: 600;
  }
}

.u-line {
  margin: 10px 0 16px;
}

.u-tip {
  margin: 8px 0 10px;
  color: #9fa5ad;
  font-size: 12px;
}

.u-video {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;

  .u-img-box {
    display: inline-block;
    height: 94px;
    background-size: cover;
    position: relative;
    text-align: center;
    border-radius: 6px;
    border: 2px solid #fff;
  }

  .m-block {
    margin-bottom: 20px;

    .u-mask {
      position: absolute;
      width: 158px;
      height: 88px;
      background: #000;
      opacity: 0.3;
      border-radius: 4px;
      display: none;
      top: 0;
      left: 0;
    }

    .u-img {
      display: inline-block;
      width: 158px;
      height: 88px;
      background-size: cover;
      position: relative;
      text-align: center;
      border-radius: 4px;

      /* .replace {
        left: 27px;
        top: 30px;
      }

      .clip {
        left: 65px;
        top: 30px;
      }

      .delete {
        left: 102px;
        top: 30px;
      } */

      .replace {
        left: 45px;
        top: 30px;
      }

      .delete {
        right: 45px;
        top: 30px;
      }

      .replace,
      .delete,
      .clip {
        position: absolute;
        font-size: 18px;
        width: 28px;
        height: 28px;
        display: inline-block;
        background: #000;
        opacity: 0;
        border-radius: 28px;
        transition: 0.4s;
      }

      &:hover {
        .replace,
        .delete,
        .clip {
          opacity: 0.65;
        }
      }
    }

    .m-set {
      display: inline-block;
      position: relative;
      height: 88px;
      text-align: center;
      margin-left: 4px;

      .u-voice {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background: #f2f3f5;
        position: absolute;
        top: 0;

        .delete {
          right: 4px;
          top: 4px;
          position: absolute;
          font-size: 18px;
          width: 28px;
          height: 28px;
          display: inline-block;
          background: #000;
          opacity: 0;
          border-radius: 24.8889px;
        }

        &:hover {
          .delete {
            position: absolute;
            font-size: 18px;
            width: 28px;
            height: 28px;
            display: inline-block;
            background: #000;
            opacity: 0.6;
            border-radius: 24.8889px;
          }
        }
      }

      .u-time {
        width: 36px;
        height: 42px;
        border-radius: 4px;
        background: #f2f3f5;
        position: absolute;
        top: 46px;
        padding-top: 3px;
      }
    }
  }

  .m-block-box {
    margin-bottom: 20px;
    width: 200px;
    height: 112px;
    position: relative;
    text-align: center;

    .m-block-video {
      width: 200px;
      height: 112px;
      background: #f2f3f5;
      border-radius: 4px;
      border: 2px solid #fff;
      overflow: hidden;
    }

    .replace {
      left: 65px;
      top: 40px;
    }

    .delete {
      right: 65px;
      top: 40px;
    }

    .replace,
    .delete {
      position: absolute;
      font-size: 18px;
      width: 28px;
      height: 28px;
      display: inline-block;
      background: #000;
      opacity: 0;
      border-radius: 28px;
      transition: 0.4s;
    }

    &:hover {
      .replace,
      .delete {
        opacity: 0.65;
      }
    }

    .vjs-poster {
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .m-pop {
    width: 226px;
    height: 58px;
  }
}

.u-time-text {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-video .u-new {
  border: 2px solid #3360ff;
}

.u-video .m-block-box .u-new {
  border: 2px solid #3360ff;
}

.ant-popover {
  width: 226px;
}

.ant-slider-track {
  background-color: #2255f4;
}

.ant-slider-handle {
  border: solid 2px #2255f4;
}
</style>
