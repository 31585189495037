<template>
  <!-- <div
    :class="className"
    :style="img ? {
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: img2x ? [`url(${img})`, `-webkit-image-set(url(${img}) 1x, url(${img2x}) 2x)`] : `url(${img})`,
      ...extStyle
    } : {
      backgroundColor: `${bgColor}`
    } "
  >
    <slot />
  </div> -->
  <div
    :class="className"
    :style="rimg ? {
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: rimg2x ? [`url(${rimg})`, `-webkit-image-set(url(${rimg}) 1x, url(${rimg2x}) 2x)`] : `url(${rimg || rimg2x})`,
      ...extStyle
    } : {
      backgroundColor: `${bgColor}`
    } "
  >
    <slot />
  </div>
</template>

<script>
import { realImageUrl } from '@/utils/utils'
export default {
  props: {
    img: {
      type: String,
      default: ''
    },
    img2x: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: '#fff'
    },
    className: {
      type: [String, Array],
      default: ''
    },
    extStyle: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    rimg() {
      return realImageUrl(this.img, this.$store.state.isSupportWebp)
    },
    rimg2x() {
      return realImageUrl(this.img2x, this.$store.state.isSupportWebp)
    }
  }
}
</script>
