<template>
  <div v-show="materialShow" :class="['m-material', materialShow ? 'show-material' : '', isPC ? '' : 'z-material-phone']">
    <a-tabs @change="callback" default-active-key="MYSELF">
      <a-tab-pane key="MYSELF" :force-render="true" tab="我的" />
      <a-tab-pane key="TEAM" :force-render="true" tab="团队" />
    </a-tabs>
    <div class="content">
      <div class="search">
        <a-input @pressEnter="searchMaterial" v-model="searchValue" class="search-input" placeholder="搜索当前素材库">
          <a-icon slot="prefix" type="search" />
        </a-input>
        <HyUpload
          :accept="accept"
          :activeTags="activeTags"
          :isMultiple="multiple"
          @updateStatus="updateStatus"
          @updateList="updateList"
          @load="load"
          style-type="material"
          class="upload-btn"
        />
      </div>
      <div class="tags">
        <a-button :class="activeTags === fileType.Image.value? 'active' : ''" @click="changeTypeList(fileType.Image.value)" :disabled="type !== fileType.Image.value && type !== fileType.ImageVideo.value" shape="circle">
          图片
        </a-button>
        <a-button :class="activeTags === fileType.Video.value? 'active' : ''" @click="changeTypeList(fileType.Video.value)" :disabled="type !== fileType.Video.value && type !== fileType.ImageVideo.value" shape="circle">
          视频
        </a-button>
        <a-button :class="activeTags === fileType.Audio.value? 'active' : ''" @click="changeTypeList(fileType.Audio.value)" :disabled="type !== fileType.Audio.value" shape="circle">
          音频
        </a-button>
      </div>
      <div
        ref="scrollDomRef"
        class="material-list menu-list-body"
      >
        <div
          ref="imgUlRef"
          v-if="activeTags === fileType.Image.value && ( ImgList1.length > 0 || ImgArr.length > 0 )"
          class="imgBox list"
        >
          <div ref="li1" class="fullBox list-item">
            <div
              v-for="(ele, index) in ImgArr"
              :key="ele.key"
              class="item-box"
            >
              <div v-if="(index % 2) === 0" class="content-box">
                <div class="videosBox">
                  <a-spin class="imgs downImgs" wrapper-class-name="upload-spin" tip="图片上传中..." />
                </div>
                <div class="textBox">
                  <p>{{ ele.name }}</p>
                </div>
              </div>
            </div>
            <div
              v-for="(ele, index) in ImgList1"
              :key="ele.key"
              @mouseenter="listMouseenter(ele.url)"
              @mouseleave="listMouseleave"
            >
              <div :class="['imgsBox', ImgcheckedIndex1 === index + 1 ? 'checked' : '']" @click="ImgcheckedIndex1 = index + 1; ImgcheckedIndex2 = null;">
                <CMSImage :img="ele.coverImage" :img2x="ele.url" class="imgs downImgs" />
                <!-- <img
                  :src="ele.url"
                  alt=""
                  class="imgs downImgs"
                > -->
                <!-- <div v-if="ele.url === iconshowNnm" class="mask">
                  {{ ele.width }} * {{ ele.height }}
                </div> -->
                <div class="mask">
                  {{ ele.width }} * {{ ele.height }}
                </div>
              </div>
              <div class="textBox">
                <p>{{ ele.name }}</p>
              </div>
            </div>
          </div>
          <div ref="li2" class="fullBox list-item">
            <div
              v-for="(ele, index) in ImgArr"
              :key="ele.key"
              class="item-box"
            >
              <div v-if="(index % 2) !== 0" class="content-box">
                <div class="videosBox">
                  <a-spin class="imgs downImgs" wrapper-class-name="upload-spin" tip="图片上传中..." />
                </div>
                <div class="textBox">
                  <p>{{ ele.name }}</p>
                </div>
              </div>
            </div>
            <div
              v-for="(ele, index) in ImgList2"
              :key="index"
              @mouseenter="listMouseenter(ele.url)"
              @mouseleave="listMouseleave"
            >
              <div :class="['imgsBox', ImgcheckedIndex2 === index + 1 ? 'checked' : '']" @click="ImgcheckedIndex2 = index + 1; ImgcheckedIndex1 = null;">
                <CMSImage :img="ele.coverImage" :img2x="ele.url" class="imgs downImgs" />
                <div class="mask">
                  {{ ele.width }} * {{ ele.height }}
                </div>
              </div>
              <div class="textBox">
                <p>{{ ele.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="activeTags === fileType.Video.value && ( VideoList.length > 0 || VideoArr.length > 0 )" class="videoBox clearfix list">
          <div
            v-for="ele in VideoArr"
            :key="ele.key"
            class="list-item video-item"
          >
            <div class="videosBox">
              <a-spin class="imgs downImgs" wrapper-class-name="upload-spin" tip="视频上传中..." />
            </div>
            <div class="textBox">
              <p>{{ ele.name }}</p>
            </div>
          </div>
          <div v-for="(ele, index) in VideoList" :key="ele.id" class="list-item video-item">
            <div :class="['videosBox', othercheckedIndex === index + 1 ? 'checked' : '']" @click="ele.transcoding ? othercheckedIndex = null : othercheckedIndex = index + 1">
              <VideoRefresh :videoData="ele" />
              <div class="mask">
                {{ ele.formatDur }}
              </div>
            </div>
            <div class="textBox">
              {{ ele.name }}
            </div>
          </div>
        </div>
        <div v-else-if="activeTags === fileType.Audio.value && ( AudioList.length > 0 || AudioArr.length > 0 )" class="audioBox clearfix list">
          <div
            v-for="ele in AudioArr"
            :key="ele.key"
            class="list-item audio-item"
          >
            <div class="videosBox">
              <a-spin class="imgs downImgs" wrapper-class-name="upload-spin" tip="音频上传中..." />
            </div>
            <div class="textBox">
              <p>{{ ele.name }}</p>
            </div>
          </div>
          <div v-for="(ele, index) in AudioList" :key="index" class="list-item audio-item">
            <div :class="['audiosBox', othercheckedIndex === index + 1 ? 'checked' : '']" @click="othercheckedIndex = index + 1">
              <audio :src="ele.url" :controls="false" class="audios">您的浏览器不支持audio标签</audio>
              <img src="/audio.png" alt="">
            </div>
            <div class="textBox">
              {{ ele.name }}
            </div>
          </div>
        </div>
        <div v-else class="u-emptyBox">
          <CMSImage img="/Home/materialEmpty.png" img2x="/Home/materialEmpty@2x.png" />
          <div>
            暂无内容，请先去
            <HyUpload
              :accept="accept"
              :isMultiple="multiple"
              :showUploadList="false"
              :activeTags="activeTags"
              @updateStatus="updateStatus"
              @updateList="updateList"
              @load="load"
              style-type="link"
              class="upload-btn"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button @click="materialShow = false">
        取消
      </a-button>
      <a-button @click="saveMaterial" type="primary">
        确定
      </a-button>
    </div>
  </div>
</template>

<script>
import { fileType, accepts } from '@/constants/fileType'
import { isVideo, isImage, isAudio } from '@/utils/is'
import { formatDate } from '@/utils/utils'
import CMSImage from './Image'
import VideoRefresh from './VideoRefresh.vue'
import HyUpload from './hyUpload.vue'
export default {
  components: {
    CMSImage,
    VideoRefresh,
    HyUpload
  },
  props: {
    isShow: {
      type: [Boolean],
      default: false
    },
    type: {
      type: [String, Array],
      default: 'IMAGE'
    },
    isPC: {
      type: Boolean
    }
  },
  data() {
    return {
      fileType,
      materialShow: false,
      showMore: true,
      pageNumber: 20,
      searchValue: null,
      activeTags: fileType.Image.value,
      uploadTag: fileType.Image.value,
      tabs: 'MYSELF',
      iconshowNnm: '',
      accept: accepts.Image.value,
      othercheckedIndex: null,
      ImgcheckedIndex2: null,
      ImgcheckedIndex1: null,
      isLoading: false,
      currentPage: 0,
      currentTotal: 0,
      ImgArr: [],
      VideoArr: [],
      AudioArr: [],
      isLt2M: false,
      ImgList1: [],
      ImgList1Height: 0,
      ImgList2: [],
      ImgList2Height: 0,
      VideoList: [],
      AudioList: [],
      scrollFlag: false,
      multiple: true
    }
  },
  watch: {
    isShow(news) {
      this.materialShow = news
    },
    materialShow(news) {
      this.othercheckedIndex = null
      this.ImgcheckedIndex1 = null
      this.ImgcheckedIndex2 = null
      this.$emit('isShowChange', news)
      this.ImgList1 = []
      this.ImgList2 = []
      this.VideoList = []
      this.AudioList = []
      if (news) {
        this.currentPage = 0
        setTimeout(() => {
          this.getListDetail()
        }, 10)
      }
    },
    type(news) {
      console.log(news)
      if (news === fileType.ImageVideo.value) {
        this.changeTypeList(fileType.Image.value)
      } else {
        this.changeTypeList(news)
      }
      setTimeout(() => {
        this.getListDetail()
      }, 10)
    }
  },
  mounted() {
    this.materialShow = this.isShow
    const _this = this
    const dom = document.querySelectorAll('.m-material .content')
    dom.forEach((item) => {
      item.addEventListener('scroll', (e) => {
        if (e.target.scrollTop && e.target.scrollHeight - e.target.scrollTop - item.offsetHeight < 150 && this.scrollFlag) {
          if (_this.currentTotal > (_this.currentPage + 1) * _this.pageNumber) {
            this.scrollFlag = false
            _this.currentPage++
            _this.getListDetail()
          }
        }
      })
    })
  },
  methods: {
    updateStatus() {
      this.getListDetail()
    },
    updateList(data) {
      this.ImgArr = []
      this.AudioArr = []
      this.VideoArr = []
      data.forEach((e) => {
        if (this.acceptFile(e.name) === fileType.Image.value) {
          this.ImgArr.push(e)
        } else if (this.acceptFile(e.name) === fileType.Audio.value) {
          this.AudioArr.push(e)
        } else {
          this.VideoArr.push(e)
        }
      })
    },
    load(data) {
      this.isLoading = data
    },
    getListDetail() {
      const _this = this
      const params = {
        name: this.searchValue,
        type: this.activeTags,
        offset: this.currentPage * this.pageNumber,
        queryType: this.tabs,
        pageSize: this.pageNumber
      }
      this.$axios.get('/bigscreen/material/page', { params }).then((res) => {
        _this.currentTotal = res.data.total
        this.scrollFlag = true
        if (_this.activeTags === fileType.Image.value) {
          if (_this.currentPage === 0) {
            _this.ImgList1Height = 0
            _this.ImgList2Height = 0
            _this.ImgList1 = []
            _this.ImgList2 = []
          }
          if (res.data.items.length > 0) {
            res.data.items.map((item, index) => {
              if (_this.ImgList1Height <= _this.ImgList2Height) {
                _this.ImgList1Height += _this.doWHradio(item)
                _this.ImgList1.push(item)
              } else {
                _this.ImgList2Height += _this.doWHradio(item)
                _this.ImgList2.push(item)
              }
            })
            // console.log('_this.ImgList1', _this.ImgList1, '_this.ImgList1Height', _this.ImgList1Height)
            // console.log('_this.ImgList2', _this.ImgList2, '_this.ImgList2Height', _this.ImgList2Height)
          } else {
            if (_this.currentPage !== 0) {
              _this.$message.error('暂无其他数据')
            }
            _this.showMore = false
          }
        } else if (_this.activeTags === fileType.Video.value) {
          if (_this.currentPage === 0) {
            _this.VideoList = res.data.items.map((value) => {
              return {
                ...value,
                formatDur: formatDate(value.duration)
              }
            })
          } else if (res.data.items.length > 0) {
            res.data.items.map((item) => {
              _this.VideoList.push(item)
            })
          } else {
            if (_this.currentPage !== 0) {
              _this.$message.error('暂无其他数据')
            }
            _this.showMore = false
          }
        } else if (_this.activeTags === fileType.Audio.value) {
          if (_this.currentPage === 0) {
            _this.AudioList = res.data.items
          } else if (res.data.items.length > 0) {
            res.data.items.map((item) => {
              _this.AudioList.push(item)
            })
          } else {
            if (_this.currentPage !== 0) {
              _this.$message.error('暂无其他数据')
            }
            _this.showMore = false
          }
        }
      })
    },
    doWHradio(ele) {
      if (ele.height && ele.width) {
        return Math.round(100 * (ele.height / ele.width))
      } else {
        return 0
      }
    },
    changeTypeList(type) {
      if (this.activeTags !== type) {
        if (type === fileType.Image.value) {
          this.accept = accepts.Image.value
        } else if (type === fileType.Video.value) {
          this.accept = accepts.Video.value
        } else {
          this.accept = accepts.Audio.value
        }
        this.activeTags = type
        this.othercheckedIndex = null
        this.ImgcheckedIndex1 = null
        this.ImgcheckedIndex2 = null
        this.currentPage = 0
        this.getListDetail()
      }
    },
    callback(value) {
      this.tabs = value
      this.othercheckedIndex = null
      this.ImgcheckedIndex1 = null
      this.ImgcheckedIndex2 = null
      this.currentPage = 0
      this.getListDetail()
    },
    listMouseenter(v) {
      this.iconshowNnm = v
    },
    listMouseleave() {
      this.iconshowNnm = ''
    },
    getActiveTag() {
      console.log('获取当前标签页', this.activeTags)
      this.uploadTag = this.activeTags
    },
    acceptFile(fileName) {
      let acceptName = fileName.split('.')[fileName.split('.').length - 1]
      acceptName = acceptName.toLocaleLowerCase()
      if (isVideo(acceptName)) {
        return fileType.Video.value
      } else if (isImage(acceptName)) {
        return fileType.Image.value
      } else if (isAudio(acceptName)) {
        return fileType.Audio.value
      } else {
        return false
      }
    },
    saveMaterial() {
      if (this.ImgcheckedIndex1 || this.ImgcheckedIndex2 || this.othercheckedIndex) {
        if (this.activeTags === fileType.Image.value && this.ImgcheckedIndex1) {
          this.$emit('MaterialReturn', this.ImgList1[this.ImgcheckedIndex1 - 1])
        } else if (this.activeTags === fileType.Image.value && this.ImgcheckedIndex2) {
          this.$emit('MaterialReturn', this.ImgList2[this.ImgcheckedIndex2 - 1])
        } else if (this.activeTags === fileType.Video.value && this.othercheckedIndex) {
          this.$emit('MaterialReturn', this.VideoList[this.othercheckedIndex - 1])
        } else if (this.activeTags === fileType.Audio.value && this.othercheckedIndex) {
          this.$emit('MaterialReturn', this.AudioList[this.othercheckedIndex - 1])
        }
        this.materialShow = false
      } else {
        this.$message.error('您尚未选择文件')
      }
    },
    searchMaterial(val) {
      this.searchValue = this.searchValue.replace(/(^\s*)|(\s*$)/g, '')
      if (this.searchValue) {
        this.currentPage = 0
        this.getListDetail()
      } else {
        this.searchValue = null
        this.currentPage = 0
        this.getListDetail()
      }
    }
  }
}
</script>
<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-material {
  z-index: 997;
  position: fixed;
  top: 60px;
  right: -334px;
  width: 334px;
  height: calc(100vh - 60px);
  background: #fff;
  border-right: 1px solid #e5e5e5;
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.1);
  transition: 0.6s;

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    left: 50%;
    transform: translateX(-50%);

    .ant-tabs-tab {
      height: 50px;
      font-size: 16px;
      line-height: 22px;
      padding: 14px 5px;
      margin: 0 40px;
    }

    .ant-tabs-tab-active {
      color: #3360ff;
    }

    .ant-tabs-ink-bar {
      background-color: #3360ff;
    }
  }

  .header {
    border-bottom: 1px solid #ddd;
    height: 50px;
    margin-bottom: 20px;

    button {
      height: 50px;
      line-height: 50px;
      border: none;
    }
  }

  .content {
    height: calc(100vh - 134px);
    padding-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .search {
    padding: 0 20px;

    .search-input {
      width: 60%;

      input {
        width: 100%;
        height: 36px;
        border-radius: 18px !important;
        background: #f5f6f7;
        border: none;
      }
    }

    .upload-btn {
      float: right;
      height: 36px;
      border-radius: 20px;
      color: #000;
    }
  }

  .tags {
    margin-top: 14px;
    padding: 0 20px;

    button {
      width: 48px;
      height: 26px;
      padding: 5px 0;
      border: none;
      background: #f5f6f7;
      border-radius: 20px;
      font-size: 12px;
      text-align: center;
      color: #000;
      margin-right: 4px;
    }

    .active {
      color: #3360ff;
      background: rgba(51, 96, 255, 0.12);
    }
  }

  .material-list {
    margin-top: 14px;
    position: relative;

    .list {
      padding: 0 14px 60px 20px;
    }

    .imgBox {
      display: flex;
      justify-content: space-between;
    }

    .fullBox {
      width: 48%;

      > div {
        margin-bottom: 10px;
      }

      .item-box {
        margin-bottom: 0;
      }

      .content-box {
        margin-bottom: 0;
      }

      img {
        width: 100%;
      }

      .imgsBox {
        position: relative;
        border-radius: 2px;
        overflow: hidden;
        border: 3px solid #fff;

        .imgs {
          z-index: 1;
        }

        .mask {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 2px;
          text-align: right;
          color: #fff;
          font-size: 12px;
          line-height: 17px;
          padding: 6px;
          z-index: 0;
          transition: opacity 0.3s ease;
        }

        &:hover {
          .mask {
            opacity: 1;
          }
        }
      }

      .checked {
        border: 3px solid #3306ff;
      }

      .textBox {
        font-size: 12px;
        line-height: 17px;
        color: #333;
        margin-top: 8px;

        p {
          margin: 0;
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .videoBox,
    & .audioBox,
    & .list {
      .list-item {
        float: left;
        width: 48%;
        margin-right: 4%;
        margin-bottom: 10px;

        .videosBox {
          border-radius: 4px;
          overflow: hidden;
          height: 80px;
          text-align: center;
          background-color: #eceff4;
          position: relative;
          border: 3px solid #fff;

          img {
            max-width: 100%;
            /* max-height: 100%; */
            max-height: 74px;
          }

          video {
            display: block;
            max-width: 100%;
            max-height: 74px;
            margin: 0 auto;
          }

          .video-mask {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            line-height: 80px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.5);
          }

          .ant-spin-spinning {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
          }
        }

        .audiosBox {
          height: 80px;
          border-radius: 4px;
          overflow: hidden;
          background: #eceff4;
          text-align: center;
          border: 3px solid #fff;

          audio {
            width: 100%;
            height: 100%;
          }

          img {
            height: 40px;
            margin-top: 20px;
          }
        }

        .textBox {
          font-size: 12px;
          line-height: 17px;
          color: #333;
          margin-top: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          p {
            margin: 0;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .checked {
          border: 3px solid #3306ff;
        }
      }

      .list-item:nth-child(2n) {
        margin-right: 0;
      }
    }

    .videosBox {
      position: relative;
      overflow: hidden;
      .mask {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        text-align: right;
        color: #fff;
        font-size: 12px;
        line-height: 17px;
        padding: 6px;
        z-index: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        .mask {
          opacity: 1;
        }
      }
    }

    .u-emptyBox {
      text-align: center;

      .ant-btn-link {
        color: #3360ff;
      }

      .ant-btn {
        padding: 0;
      }
    }

    .m-spinning-box {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #fff;
    padding: 14px 8px;
    border-top: 1px solid #ddd;
    text-align: right;

    button {
      padding: 6px 20px;
      border-radius: 30px;
      font-size: 14px;
      line-height: 20px;
      margin-right: 10px;
    }

    .ant-btn-primary {
      color: #fff;
    }
  }

  .content::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
  }

  .content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d3d8db;
  }
}

.show-material {
  right: 240px;
}

.z-material-phone {
  right: 0;
  z-index: 1002;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.display-block {
  display: inline-block;
}
</style>
