const VIDEO_EXPRESSION = /(avi|wma|rmvb|rm|flash|mp4|mid|3gp|video|wmv|avchd|webm|mkv|flv|mov)(\?.*)?$/
const PICTURE_EXPRESSION = /(png|jpe?g|gif|svg|tiff|webp|bmp)(\?.*)?$/
const AUDIO_EXPRESSION = /(mp3|MP3|aac|wma|wav|ape|flac|ogg)(\?.*)?$/
const picReg = new RegExp(PICTURE_EXPRESSION)
const videoReg = new RegExp(VIDEO_EXPRESSION)
const audioReg = new RegExp(AUDIO_EXPRESSION)

export const isVideo = (url) => {
  return videoReg.test(url)
}

export const isImage = (url) => {
  return picReg.test(url)
}

export const isAudio = (url) => {
  return audioReg.test(url)
}

export function isNumber(value) {
  return typeof value === 'number' && !isNaN(value)
}
