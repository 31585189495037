<template>
  <a-upload
    :list-type="styleType === 'directUpload' ? 'picture-card' : 'text'"
    :class="styleType === 'directUpload' ? 'avatar-uploader' : ''"
    :show-upload-list="false"
    :multiple="isMultiple"
    :accept="accept"
    :custom-request="handleChange"
    action=""
  >
    <div v-if="styleType === 'material'">
      <a-button @click="getActiveTag" type="primary">
        <a-icon type="plus" />上传素材
      </a-button>
    </div>
    <div v-else>
      <a-button @click="getActiveTag" type="link">
        上传素材
      </a-button>
    </div>
  </a-upload>
</template>

<script>

import { isVideo, isImage, isAudio } from '@/utils/is'
import { fileType } from '@/constants/fileType'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png, .bmp'
    },
    styleType: {
      type: String,
      default: 'directUpload'
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    activeTags: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileType,
      loading: false,
      imageUrl: this.value,
      percentage: 0,
      isJpgOrPng: false,
      isLimt: false,
      list: [],
      arr: [],
      uploadTag: this.activeTags,
      VideoArr: [],
      number: true
    }
  },
  watch: {
    value(val = '') {
      this.imageUrl = val
    }
  },
  methods: {
    getActiveTag() {
      this.uploadTag = this.activeTags
    },
    handleChange(info) {
      const newTag = this.uploadTag
      if (info.file.size === 0) {
        this.$message.error('请上传正确的文件')
        return
      }
      if (this.list.length > 17) {
        if (this.number) {
          this.$message.error('同时在上传的文件不能超过18个')
          this.number = false
        }
        return
      }
      if (this.acceptFile(info.file.name) !== this.uploadTag) {
        this.$message.error('上传文件格式不正确')
        return
      }
      if (this.limitDetect(info.file.size)) {
        this.$message.warning(`文件大小不能超过${this.limit}M`)
        return
      }
      this.list.push({ key: info.file.uid, name: info.file.name, file: info })
      this.$emit('updateList', this.list)
      this.$axios.get('/bigscreen/quota/remain/space_size').then((res) => {
        if (info.file.size > res.content) {
          this.$message.error('文件大小超过剩余空间额度')
        } else if (newTag !== fileType.Video.value) {
          this.loading = true
          this.$emit('load', true)
          this.number = true
          const uploader = this.$NosUploader({
            app: 'cms',
            code: process.env.UPLOAD_BIZ_CODE || 'creative-cms',
            securityCheck: false,
            singleton: true,
            onBeforeRequest: (url, params) => {
              params.custom = 'node'
              url = '/node' + url
              return { url, params }
            }
          })
          uploader.uploads([info.file], {
            onProgress: (res) => {
              console.log(`上传进度${res.percent}`)
            }
          }).then((res) => {
            this.imageUrl = res[0].url
            this.loading = false
            this.$emit('load', false)
            const params = {
              type: newTag,
              name: info.file.name,
              size: info.file.size,
              url: res[0].url
            }
            this.$axios.post('/bigscreen/material/save', params).then((res) => {
              if (res.data.content === 200) {
                this.list.map((val, index) => {
                  if (val.key === info.file.uid) {
                    this.list.splice(index, 1)
                    this.$emit('updateList', this.list)
                  }
                })
                console.log('保存成功')
                this.$emit('updateStatus')
              }
            })
          }).catch((err) => {
            this.loading = false
            this.$emit('load', false)
            this.$message.error(`上传失败${err}`)
          })
        } else {
          this.VideoArr.push({ key: info.file.uid, name: info.file.name, progress: 0 })
          this.$emit('updateList', this.VideoArr)
          const params = {
            originFileName: info.file.name,
            userFileName: info.file.name
          }
          this.$axios.post('/bigscreen/vod/upload/init', params).then((res) => {
            this.VideoArr.forEach((val) => {
              if (val.key === info.file.uid) {
                val.fileInitData = res.data
                val.bucket = res.bucket
              }
            })
            this.fileInitData = res.data
            this.bucket = res.bucket
            this.getUrl().then((data) => {
              this.uploadVideo(
                info.file.size,
                info.file,
                {
                  context: '',
                  offset: 0
                },
                info.file.uid,
                res.data
              )
            }).catch(() => {
              this.$emit('load', false)
            })
          }).catch((err) => {
            console.log(err)
            this.$emit('load', false)
          })
        }
      })
    },
    // 获取真正的上传地址
    getUrl() {
      const params = {
        version: 1.0,
        bucketname: this.bucket
      }
      return new Promise((resolve) => {
        this.$axios
          .get('https://wanproxy.127.net/lbs', {
            params
          })
          .then((res) => {
            resolve(res)
            this.uploadUrl = 'https://wanproxy-web.127.net'
          })
      })
    },
    // 上传视频
    uploadVideo(size, file, data, uid, fileInitData) {
      let result = {}
      let xhrParam = ''
      const xhr = new XMLHttpRequest()
      const _this = this
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          // progress = (this.videoParam.offset + e.loaded) / file.size
          // curFile.progress = (progress * 100).toFixed(2)
        } else {
          _this.$message.error('浏览器不支持进度事件')
        }
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState !== 4) {
          return
        }
        try {
          result = JSON.parse(xhr.responseText)
        } catch (e) {
          result = {
            errCode: 500,
            errMsg: '未知错误'
          }
        }
        console.log('result', result)
        if (xhr.status === 200) {
          if (!result.errCode) {
            _this.videoParam = { ...result }
            _this.VideoArr.forEach((val) => {
              val.size = file.size
              val.status = 'ready'
              val.uid = val.key
              val.percentage = 0
              if (val.key === uid) {
                val.result = result
                val.progress = (result.offset / file.size) * 100
                if (result.offset < file.size) {
                  _this.uploadVideo(size, file, result, uid, val.fileInitData)
                } else {
                  _this.uploadSuccess({ objectName: fileInitData.object }, uid)
                  val.progress = 0
                }
              }
            })
          } else {
            this.$emit('load', false)
            _this.$message.error(result.errMsg)
          }
        }
      }
      xhrParam = '?offset=' + data.offset + '&complete=' + (data.offset + 4 * 1024 * 1024 >= file.size) + '&context=' + data.context + '&version=1.0'

      xhr.open('post', this.uploadUrl + '/' + fileInitData.bucket + '/' + fileInitData.object + xhrParam)
      xhr.setRequestHeader('x-nos-token', fileInitData.xNosToken)
      console.log('result.offset', data)
      xhr.send(file.slice(data.offset || 0, (data.offset || 0) + 4 * 1024 * 1024))
    },
    uploadSuccess(data, uid) {
      this.$axios.post('/bigscreen/vod/upload/success', data).then((res) => {
        let params = []
        console.log('this.VideoArr', this.VideoArr)
        this.VideoArr.map((val) => {
          if (val.uid === uid) {
            params = [{
              fileName: val.name,
              objectName: val.fileInitData ? val.fileInitData.object : ''
            }]
          }
        })
        this.$axios.post('/bigscreen/vod/generate', { recVideoGenerates: params }).then((res) => {
          this.VideoArr.map((val, index) => {
            if (val.key === uid) {
              this.VideoArr.splice(index, 1)
            }
          })
          if (this.VideoArr.length === 0 && this.activeTags === fileType.Video.value) {
            // this.currentPage = 0
            // this.othercheckedIndex = null
            this.$message.success('上传成功')
            this.$emit('updateList', this.VideoArr)
            this.$emit('updateStatus')
          }
          this.$emit('load', false)
        }).catch(() => {
          this.$emit('load', false)
        })
      }).catch(() => {
        this.$emit('load', false)
      })
    },
    limitDetect(fileSize) {
      switch (this.uploadTag) {
        case fileType.Video.value:
          this.limit = 10240
          break
        case fileType.Image.value:
          this.limit = 18
          break
        case fileType.Audio.value:
          this.limit = 100
          break
      }
      if (this.uploadTag === fileType.Video.value) {
        return fileSize / 1024 / 1024 / 1024 > Number(this.limit)
      } else {
        return fileSize / 1024 / 1024 > Number(this.limit)
      }
    },
    acceptFile(fileName) {
      let acceptName = fileName.split('.')[fileName.split('.').length - 1]
      acceptName = acceptName.toLocaleLowerCase()
      if (isVideo(acceptName)) {
        return fileType.Video.value
      } else if (isImage(acceptName)) {
        return fileType.Image.value
      } else if (isAudio(acceptName)) {
        return fileType.Audio.value
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="less">
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
  background: #F8F9FB;
  .anticon-plus {
    color: #000;
  }
  .ant-upload-text {
    color: #000;
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>

<style lang="less" scoped>
.u-site-img {
  max-width: 200px;
}
</style>
