<template>
  <div>
    <a-form-item v-if="value.type === 'List'" :label="value.list || '卡片列表'">
      <VisualList
        :visualKeys="value.visualKeys"
        :noTreeSwitcher="true"
        @change="visualChange"
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: [...value.value],
            rules: value.required ? [{ required: true, message: '列表不能为空' }] : []
          }
        ]"
        :visualValue="value.visualValue"
      />
    </a-form-item>
    <a-form-item v-else-if="value.type === 'Text'" :label="value.title">
      <a-input
        @change="visualChange"
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value,
            rules: value.required ? [{ required: true, message: '值不能为空且不能超过25个字符', max: 25, whitespace: true }] : [{message: '值不能超过25个字符', max: 25, whitespace: true}]
          }
        ]"
      />
    </a-form-item>
    <a-form-item v-else-if="value.type === 'MultiText'" :label="value.title">
      <a-textarea
        @change="visualChange"
        :autoSize="{ minRows: 3, maxRows: 5 }"
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value,
            rules: value.required ? [{ required: true, message: '请输入', whitespace: true }] : []
          }
        ]"
      />
    </a-form-item>
    <a-form-item v-else-if="value.type === 'Poster'">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value,
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader u-video-poster"
      >
        <span class="u-video-title">自定义视频封面</span>
        <a-switch @change="changePoster" v-model="isChangePoster" />
        <div v-if="isChangePoster">
          <div class="u-video-subtitle">
            {{ value.title }}
          </div>
          <div v-if="value.value === 'poster:'" @click="toMaterial({type: fileType.Image.value, isShow: true})" class="u-default">
            <img src="../../static/GuoFang/upload.svg" alt="">
          </div>
          <div v-else class="u-exist">
            <div class="u-mask" />
            <CMSImageBg :img="getPoster(value.value)" :img2x="getPoster(value.value)" class="u-img">
              <div @click="toMaterial({type: fileType.Image.value, isShow: true})" class="replace" title="替换">
                <img src="../../static/GuoFang/replace.svg" alt="">
              </div>
              <div @click="toClip(value)" class="replace clip" title="裁剪">
                <img src="../../static/GuoFang/clip.svg" alt="">
              </div>
            </CMSImageBg>
          </div>
        </div>
      </div>
    </a-form-item>
    <a-form-item v-else-if="value.type === 'Image'" :label="value.title">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value,
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader"
      >
        <div v-if="!value.value" class="u-default" title="上传">
          <img src="../../static/GuoFang/upload.svg" alt="">
        </div>
        <div class="u-exist">
          <!-- {{ value }} -->
          <div class="u-mask" />
          <CMSImageBg :img="value.value" :img2x="value.value" class="u-img">
            <div @click="toMaterial({type: fileType.Image.value, isShow: true})" class="replace" title="替换">
              <img src="../../static/GuoFang/replace.svg" alt="">
            </div>
            <div @click="toClip(value)" class="replace clip" title="裁剪">
              <img src="../../static/GuoFang/clip.svg" alt="">
            </div>
          </CMSImageBg>
        </div>
      </div>
    </a-form-item>
    <a-form-item v-else-if="value.type === 'BgImage'" :label="value.title">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value,
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader"
      >
        <div v-if="!value.value" class="u-default" title="上传">
          <img src="../../static/GuoFang/upload.svg" alt="">
        </div>
        <img v-else :src="value.value" alt="avatar" class="u-img">
      </div>
    </a-form-item>
    <a-form-item v-else-if="value.type === 'Video'" :label="value.title" class="videoItem">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value,
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader"
      >
        <div class="u-mask" />
        <CMSVideo :videoUrl="getAudio(value.value)" :controls="false" class="u-video" />
        <div @click="toMaterial({type: fileType.Video.value, isShow: true})" class="replace" title="替换">
          <img src="../../static/GuoFang/replace.svg" alt="">
        </div>
      </div>
      <div v-if="isBigScreen">
        <a-switch @change="onChange" v-model="isCheckedSwitch" />
        <span>进入页面自动播放视频</span>
      </div>
    </a-form-item>
    <a-form-item v-else-if="value.type === 'Audio'" :label="value.title" class="audioItem">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: value.value
          }
        ]"
        class="avatar-uploader"
      >
        <div v-if="value.value" class="audioBox">
          <img src="../../static/audio.png" alt="">
          <div @click="AudiovisualDelete()" class="delete" title="删除">
            <img src="../../static/GuoFang/delete.svg" alt="">
          </div>
        </div>
        <div v-else class="audioBox">
          <a-button @click="toMaterial({type: fileType.Audio.value, isShow: true})" icon="plus" />
        </div>
      </div>
      <div v-if="value.value">
        <a-switch @change="onChange" v-model="isCheckedSwitch" />
        <span>进入页面自动播放音频</span>
      </div>
    </a-form-item>
    <template v-else-if="value.type === 'Link'">
      <a-form-item :label="value.title">
        <a-input
          @change="visualChange"
          v-decorator="[
            value.visualKeys.join('_'),
            {
              initialValue: (value.value && value.value.replace) ? value.value.replace(/^target:/, '') : '',
              rules: value.required ? [{ required: true, message: '请输入' }, {max: 150, message: '不能超过150字符'}] : [{max: 150, message: '不能超过150字符'}]
            }
          ]"
        />
      </a-form-item>
      <a-form-item label=" ">
        <a-checkbox :checked="value.value && value.value.indexOf && value.value.indexOf('target:') === 0" @change="linkTargetChange">
          是否新页面打开
        </a-checkbox>
      </a-form-item>
    </template>
    <template v-else-if="value.type === 'Radio'">
      <a-form-item :label="value.title">
        <a-radio-group
          @change="visualChange"
          v-decorator="[
            value.visualKeys.join('_'),
            {
              initialValue: value.value || value.developValue,
              rules: value.required ? [{ required: true, message: '请选择' }] : []
            }
          ]"
        >
          <a-radio
            v-for="(item, index) in value.options"
            :key="`${value.visualKeys.join('_')}_${index}`"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- <div class="color-index">
        <div :style="{'background-color': dataList.bgColor.value}" class="u-box" />
        <a-popover v-model="isClosed" trigger="click" overlay-class-name="m-pop-class">
          <template slot="content">
            <SketchPicker class="u-color-picker" />
          </template>
          <img @click="changeColor" src="../../static/GuoFang/color.png" class="u-color">
        </a-popover>
      </div> -->
    </template>
    <template v-else-if="value.type === 'VideoRadio'">
      <a-form-item :label="value.title">
        <a-radio-group
          @change="visualChange"
          v-decorator="[
            value.visualKeys.join('_'),
            {
              initialValue: value.value || value.developValue,
              rules: value.required ? [{ required: true, message: '请选择' }] : []
            }
          ]"
        >
          <div
            v-for="(item, index) in value.options"
            :key="`${value.visualKeys.join('_')}_${index}`"
            class="video-radio"
          >
            <a-radio
              :value="item.value"
            >
              <div class="video-img">
                <img
                  v-if="item.value === 'videoLeft'"
                  src="../../static/VideoMiddle/videoLeft.png"
                  alt=""
                >
                <img
                  v-else
                  src="../../static/VideoMiddle/videoRight.png"
                  alt=""
                >
              </div>
              <span class="video-text">
                {{ item.text }}
              </span>
            </a-radio>
          </div>
        </a-radio-group>
      </a-form-item>
    </template>
    <a-form-item v-else-if="value.type === 'VideoImageGroup'" :label="value.title">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: [...value.value],
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader"
      >
        <div v-if="value.value[0].videoUrl.value === ''" class="u-video-box">
          <div class="u-exist">
            <div class="u-mask" />
            <CMSImageBg :img="value.value[0].img.value" :img2x="value.value[0].img_2x.value" class="u-img">
              <div @click="toMaterial({type: fileType.ImageVideo.value, isShow: true})" class="replace" title="替换">
                <img src="../../static/GuoFang/replace.svg" alt="">
              </div>
            </CMSImageBg>
          </div>
          <div v-if="value.value[0].textAudio.value && isShowAudio" class="audioBox">
            <img src="../../static/audio.png" alt="">
            <div @click="AudiovisualDelete()" class="delete" title="删除">
              <img src="../../static/GuoFang/delete.svg" alt="">
            </div>
          </div>
          <div v-else-if="isShowAudio" class="audioBox">
            <a-button @click="toMaterial({type: fileType.Audio.value, isShow: true})" icon="plus" />
          </div>
          <div v-if="value.value[0].textAudio.value && isShowAudio">
            <a-switch @change="onChange" v-model="isCheckedSwitch" />
            <span>进入页面自动播放音频</span>
          </div>
        </div>
        <div v-else class="u-video-box">
          <div class="videoWrap">
            <CMSVideo :videoUrl="value.value[0].videoUrl.value.replace(/^autoplay:/, '')" :controls="false" :poster="value.value[0].poster.value" />
            <div @click="toMaterial({type: fileType.ImageVideo.value, isShow: true})" class="replace" title="替换">
              <img src="../../static/GuoFang/replace.svg" alt="">
            </div>
          </div>
          <div class="isAutoWrap">
            <a-switch @change="onChange" v-model="isCheckedSwitch" />
            <span>进入页面自动播放视频</span>
          </div>
        </div>
      </div>
    </a-form-item>
    <a-form-item v-else-if="value.type === 'VideoImageList'">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: [...value.value],
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader"
      >
        <VisualScreenSaver
          :visualKey="value.visualKeys"
          @changeStatus="toMaterial"
          @change="visualChange"
        />
      </div>
      <VisualUpload
        :visualKeys="value.visualKeys"
        :visualValue="value.visualValue"
        @change="visualChange"
        @changeStatus="toMaterial"
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: [...value.value],
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
      />
    </a-form-item>
    <a-form-item v-else-if="value.type === 'MutiBgImage'" label="背景设置">
      <div
        v-decorator="[
          value.visualKeys.join('_'),
          {
            initialValue: [...value.value],
            rules: value.required ? [{ required: true, message: '请上传' }] : []
          }
        ]"
        class="avatar-uploader"
      >
        <Color
          :colorData="value.value"
          @changeBgImg="toMaterial"
          @changeBg="changeBg"
        />
      </div>
    </a-form-item>
  </div>
</template>

<script>
import VisualList from './VisualList'
import CMSImageBg from '~/components/common/ImageBg'
import { fileType } from '@/constants/fileType'
import VisualUpload from './VisualUpload'
import VisualScreenSaver from './VisualScreenSaver'
import Color from './BgColor.vue'
import CMSVideo from './Video.vue'
// import { Sketch } from 'vue-color'

export default {
  components: {
    VisualList,
    CMSImageBg,
    VisualUpload,
    VisualScreenSaver,
    Color,
    CMSVideo
    // SketchPicker: Sketch
  },
  props: {
    value: {
      type: [Array, Object, String],
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      isChecked: false,
      fileType,
      isShowAudio: this.$store.state.isShowAudio,
      isBigScreen: this.$store.state.isBigScreen
    }
  },
  computed: {
    iframe() {
      return this.$store.state.iframe
    },
    isCheckedSwitch: {
      get() {
        if (this.value.type === 'VideoImageGroup') {
          const autoValue = this.value.value[0].videoUrl.value !== '' ? this.value.value[0].videoUrl.value : this.value.value[0].textAudio.value

          return autoValue !== '' && autoValue.indexOf && autoValue.indexOf('autoplay:') === 0
        } else {
          return this.value.value !== '' && this.value.value.indexOf && this.value.value.indexOf('autoplay:') === 0
        }
      },
      set(value) {
        return value
      }
    },
    isChangePoster: {
      get() {
        const posterValue = this.value.value
        return posterValue !== '' && posterValue.indexOf && posterValue.indexOf('poster:') === 0
      },
      set(value) {
        return value && this.value.value.indexOf && this.value.value.indexOf('poster:') === 0
      }
    }
  },
  methods: {
    visualChange(e) {
      if (e.target && e.target.value) {
        if (this.value.type === 'Link' && this.value.value.indexOf('target:') !== -1) {
          // 此处需要处理是否新开
          this.value.value = 'target:' + e.target.value

          this.$emit('visualChange', {
            e: this.value.value,
            value: this.value
          })

          return
        } else {
          this.value.value = e.target.value
        }
      }
      console.log('visualChange', e, this.value)
      this.$emit('visualChange', {
        e: e,
        value: this.value
      })
    },
    AudiovisualDelete() {
      if (this.value.type === 'VideoImageGroup') {
        this.value.value[0].textAudio.value = ''
        this.$emit('visualChange', {
            e: '',
            value: this.value.value[0].textAudio
          })
      } else {
        this.value.value = ''
        this.$emit('visualChange', {
          e: '',
          value: this.value
        })
      }
    },
    changePoster(checked) {
      let posterValue = this.value.value
      posterValue = (posterValue.length !== 0 ? posterValue.replace(/^poster:/, '') : '')
      if (checked) {
        posterValue = 'poster:' + this.value.value
      }

      this.value.value = posterValue
      this.$emit('visualChange', {
        e: posterValue,
        value: this.value
      })
    },
    onChange(checked) {
      if (this.value.type === 'VideoImageGroup') {
        if (this.value.value[0].img.value !== '' && this.value.value[0].textAudio.value === '') {
          this.$message.error('请先选择音频')
        } else if (this.value.value[0].textAudio.value !== '') {
          let audioValue = this.value.value[0].textAudio.value
          audioValue = audioValue ? audioValue.replace(/^autoplay:/, '') : ''
          if (checked) {
            audioValue = 'autoplay:' + this.value.value[0].textAudio.value
          }

          this.value.value[0].textAudio.value = audioValue

          this.$emit('visualChange', {
            e: audioValue,
            value: this.value.value[0].textAudio
          })
        } else if (this.value.value[0].videoUrl.value !== '') {
          let audioValue = this.value.value[0].videoUrl.value
          audioValue = audioValue ? audioValue.replace(/^autoplay:/, '') : ''
          if (checked) {
            audioValue = 'autoplay:' + this.value.value[0].videoUrl.value
          }

          this.value.value[0].videoUrl.value = audioValue

          this.$emit('visualChange', {
            e: audioValue,
            value: this.value.value[0].videoUrl
          })
        }
      } else {
        let audioValue = this.value.value
        audioValue = audioValue ? audioValue.replace(/^autoplay:/, '') : ''
        if (checked) {
          audioValue = 'autoplay:' + this.value.value
        }

        this.value.value = audioValue

        this.$emit('visualChange', {
          e: audioValue,
          value: this.value
        })
      }
    },
    linkTargetChange(e) {
      let link = this.value.value
      link = link ? link.replace(/^target:/, '') : ''

      if (e.target.checked) {
        link = 'target:' + link
      }

      this.value.value = link
      e.target.value = link

      this.$emit('visualChange', {
        e: e,
        value: this.value
      })
    },
    toMaterial(type) {
      console.log('父子传值', type)
      this.$emit('materialChange', { ...type })
    },
    toClip(value) {
      this.$emit('clipChange', value)
    },
    changeBg({ value, obj }) {
      console.log(value)
      this.$emit('visualChange', {
        e: value,
        value: obj
      })
    },
    getAudio(value) {
      if (value.indexOf('autoplay:') !== -1) {
        value = value.replace('autoplay:', '')
      }
      return value
    },
    getPoster(value) {
      if (value.indexOf('poster:') !== -1) {
        value = value.replace(/^poster:/, '')
      }
      return value
    }
  }
}
</script>
<style lang="postcss" scoped>
.u-exist {
  width: 200px;
  height: 114px;

  .u-img {
    height: 100%;
    background-size: contain;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    background-color: #F8F9FB;

    >div:last-child {
      margin-right: 0px;
    }

    .replace,
    .delete,
    .clip {
      width: 28px;
      height: 28px;
      display: inline-block;
      background: #000;
      opacity: 0;
      border-radius: 25px;
      text-align: center;
      transition: 0.4s;
      margin-right: 10px;
      /* width: 28px;
      height: 28px;
      display: inline-block;
      background: #000;
      opacity: 0;
      border-radius: 24.8889px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      transition: 0.4s; */

      img {
        line-height: 28px;
        vertical-align: bottom;
      }
    }
  }

  .u-img:hover {
    .replace,
    .delete {
      opacity: 0.65;
    }
  }

  .u-mask {
    position: absolute;
    background: #000;
    opacity: 0.3;
    border-radius: 4px;
  }
}

.video-radio {
  .ant-radio-wrapper-checked {
    .video-img {
      border: 1px solid #3360FF;
      display: inline-block;
    }
  }

  .video-img {
    border: 1px solid rgba(0, 0, 0, 0);
    display: inline-block;
  }

  .video-text {
    display: block;
    margin: 9px 0 0 25px;;
  }
}

.u-video-poster {
  .ant-switch {
    float: right;
    margin: 0;
  }

  .u-video-title {
    color: #000;
  }

  .u-video-subtitle {
    margin: 9px 0 2px 0;
  }
}

.u-default {
  width: 200px;
  height: 112px;
  line-height: 100px;
  margin: 0 auto;
  text-align: center;
  background-color: #f8f9fb;
  border-radius: 4px;
}

.audioItem {
  .audioBox {
    margin: 10px 0;
    height: 40px;
    width: 40px;

    img {
      max-height: 40px;
    }

    button {
      width: 40px;
      height: 40px;
    }
  }

  .audioBox:hover .delete {
    display: block;
  }

  .delete {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 15px;
    left: 5px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    text-align: center;

    img {
      height: 20px;
      margin-top: 5px;
    }
  }
}

.videoItem {
  .avatar-uploader {
    position: relative;

    video {
      max-width: 100%;
    }
  }

  .u-video {
    height: 112.5px;
    border-radius: 4px;
    overflow: hidden;
  }

  .replace {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.videoItem:hover {
  .replace {
    display: block;
  }
}

.u-video-box {
  .videoWrap {
    width: 200px;
    height: 112.5px;
    position: relative;
  }

  .replace {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .isAutoWrap,
  & .audioBox {
    margin: 10px 0;
    position: relative;
  }

  .audioBox:hover .delete {
    display: block;
  }

  .delete {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 6px;
    left: 5px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    text-align: center;

    img {
      height: 20px;
      margin-top: 5px;
    }
  }
}
.color-index {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 40px;
  background: #f8f9fb;
  border-radius: 4px;
  margin: 8px 0 24px;

  .u-box {
    display: inline-block;
    width: 48px;
    height: 24px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .u-color {
    display: inline-block;
    margin-left: 12px;
  }
}
</style>

<style>
/* .ant-form-item-label > label {
  font-weight: 600;
} */
</style>
