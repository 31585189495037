<template>
  <div class="g-color-picker">
    <a-divider class="u-line" />
    <div class="u-title">
      纯色背景
    </div>
    <div class="m-color">
      <div :style="{'background-color': dataList.bgColor.value}" class="u-box" />
      <a-popover v-model="isClosed" trigger="click" overlay-class-name="m-pop-class">
        <template slot="content">
          <chrome-picker :value="dataList.bgColor.value" @input="change" class="u-color-picker" />
        </template>
        <img @click="changeColor" src="../../static/GuoFang/color.png" class="u-color">
      </a-popover>
    </div>
    <div v-if="dataList.bgImg.value === ''" class="u-exist">
      <div class="u-title">
        背景图片
      </div>
      <div @click="changeImage(fileType.Image.value)" class="u-default">
        <img src="../../static/GuoFang/upload.svg" alt="" class="u-upload">
      </div>
      <div class="u-title">
        背景图片会覆盖纯色背景
      </div>
    </div>
    <div v-else class="u-exist">
      <div class="u-mask" />
      <div class="u-title">
        背景图片
      </div>
      <CMSImageBg :img="dataList.bgImg.value" class="u-img">
        <div @click="changeImage(fileType.Image.value)" class="replace">
          <img src="../../static/GuoFang/replace.svg" alt="">
        </div>
        <div @click="onDelete" class="delete">
          <img src="../../static/GuoFang/delete.svg" alt="">
        </div>
      </CMSImageBg>
      <div class="u-title">
        背景图片会覆盖纯色背景
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import CMSImageBg from '~/components/common/ImageBg'
import { fileType } from '@/constants/fileType'

export default {
  components: {
    'chrome-picker': Chrome,
    CMSImageBg
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    colorData: {
      type: [Array, Object, String],
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      fileType,
      isClosed: false,
      dataList: this.colorData[0]
    }
  },
  mounted() {
    console.log('data', this.dataList)
  },
  methods: {
    changeImage(type) {
      this.$emit('changeBgImg', { type, isShow: true })
    },
    changeColor() {
      this.isClosed = true
    },
    change(e) {
      this.dataList.bgColor.value = e.hex
      this.$emit('changeBg', { value: e.hex, obj: this.dataList.bgColor })
    },
    onDelete() {
      this.dataList.bgImg.value = ''
      this.dataList.bgImg_2x.value = ''

      this.$emit('changeBg', { value: '', obj: this.dataList.bgImg })
      this.$emit('changeBg', { value: '', obj: this.dataList.bgImg_2x })
    }
  }
}
</script>

<style lang="postcss">
.g-color-picker {
  position: relative;

  .u-line {
    margin: 10px 0 16px;
  }

  .u-title {
    margin-top: 8px;
    color: #9fa5ad;
    font-size: 12px;
  }

  .m-color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 40px;
    background: #f8f9fb;
    border-radius: 4px;
    margin: 8px 0 24px;

    .u-box {
      display: inline-block;
      width: 48px;
      height: 24px;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .u-color {
      display: inline-block;
      margin-left: 12px;
    }
  }

  .u-exist {
    width: 200px;

    .u-title {
      margin-bottom: 8px;
      color: #9fa5ad;
      font-size: 12px;
    }

    .u-img {
      /* height: 100%; */
      height: 114px;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        .replace,
        .delete {
          opacity: 0.65;
        }
      }

      >div:last-child {
        margin-right: 0px;
      }

      .replace,
      .delete {
        width: 28px;
        height: 28px;
        display: inline-block;
        background: #000;
        opacity: 0;
        border-radius: 25px;
        text-align: center;
        transition: 0.4s;
        margin-right: 10px;

        img {
          line-height: 28px;
          vertical-align: bottom;
        }
      }

      .replace {
        left: 60px;
      }

      .delete {
        right: 60px;
      }
    }

    .u-mask {
      position: absolute;
      background: #000;
      opacity: 0.3;
      border-radius: 4px;
    }
  }

  .u-default {
    width: 200px;
    height: 114px;
    line-height: 100px;
    margin: 0 auto;
    text-align: center;
    background-color: #f8f9fb;
    border-radius: 4px;

    .u-upload {
      margin-top: 14px;
    }
  }
}

.m-pop-class {
  .ant-popover-inner-content {
    padding: 0;
    border-radius: 6px;
  }

  .ant-popover-inner {
    border-radius: 10px;
  }

  .ant-popover-arrow {
    display: none !important;
  }
}

.vc-chrome-saturation-wrap {
  border-radius: 6px !important;
}

.vc-chrome {
  border-radius: 10px !important;
}

.vc-chrome-body {
  border-radius: 10px !important;
}

.vc-chrome-color-wrap {
  display: none !important;
}
</style>
