<template>
  <img :class="className" :src="rimg || rimg2x" :srcset="rimg2x ? `${rimg} 1x,${rimg2x} 2x` : ''">
</template>

<script>
import { realImageUrl } from '@/utils/utils'
export default {
  props: {
    img: {
      type: String,
      default: ''
    },
    img2x: {
      type: String,
      default: ''
    },
    className: {
      type: [String, Array],
      default: ''
    }
  },
  computed: {
    rimg() {
      return realImageUrl(this.img, this.$store.state.isSupportWebp)
    },
    rimg2x() {
      return realImageUrl(this.img2x, this.$store.state.isSupportWebp)
    }
  }
}
</script>
