<template>
  <a-spin :spinning="spinning" wrapper-class-name="video-spin" tip="视频转码中...">
    <img v-if="videoData.coverImage" :src="videoData.coverImage" alt="" class="imgs downImgs">
    <video v-else :src="videoData.url" :poster="videoData.coverImage" class="videos" />
  </a-spin>
</template>

<script>
export default {
  props: {
    videoData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      intervalKey: null,
      intervalTime: 5,
      spinning: false
    }
  },
  mounted() {
    if (this.videoData.transcoding === true) {
      this.spinning = true
      this.refreshData()
    }
  },
  destroyed() {
    clearInterval(this.intervalKey)
  },
  methods: {
    refreshData() {
      this.intervalKey = setInterval(() => {
        this.$axios.get(`/bigscreen/material/transcode/${this.videoData.id}`).then((res) => {
          if (res.status === 200) {
            const resData = res.data
            if (resData.transcoding === true) {
              if (this.intervalTime < 60) {
                this.intervalTime = this.intervalTime * 2
              }
              clearInterval(this.intervalKey)
              this.refreshData()
            } else {
              this.spinning = false
              console.log('转码结束')
              clearInterval(this.intervalKey)
              this.videoData.transcoding = resData.transcoding
              this.videoData.coverImage = resData.coverImage
              this.videoData.url = resData.url
            }
          }
        })
      }, parseInt(this.intervalTime * 1000))
    }
  }
}
</script>

<style lang="less">
.video-spin {
  .ant-spin {
    font-size: 13px;
    max-height: 74px;
  }
}
</style>
