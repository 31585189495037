<template>
  <section class="videoHls">
    <div
      ref="videoPlayer"
      v-video-player:myVideoPlayer="playerOptions"
      :playsinline="playsinline"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @loadeddata="onPlayerLoadeddata($event)"
      @waiting="onPlayerWaiting($event)"
      @playing="onPlayerPlaying($event)"
      @timeupdate="onPlayerTimeupdate($event)"
      @canplay="onPlayerCanplay($event)"
      @canplaythrough="onPlayerCanplaythrough($event)"
      @ready="playerReadied"
      @statechanged="playerStateChanged($event)"
      class="video-player-box"
    />
    <!-- <video :src="videoUrl" /> -->
  </section>
</template>

<script>
const videoMap = {
  m3u8: 'application/x-mpegURL',
  mp4: 'video/mp4',
  flv: 'video/flv'
}
export default {
  props: {
    // 视频地址
    videoUrl: {
      type: String,
      default: ''
    },
    // 自动播放
    autoPlay: {
      type: Boolean,
      default: false
    },
    // 循环播放
    loop: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: true
    },
    poster: {
      type: String,
      default: require('../../static/videoPoster.png')
    },
    fullscreenToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      playsinline: true,
      playerOptions: {}
    }
  },
  watch: {
    videoUrl(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.dealSources(newValue)
      }
    }
  },
  mounted() {
    let videoUrl = this.videoUrl; let autoPlay = false
    if (this.videoUrl.indexOf('autoplay:') !== -1) {
      videoUrl = this.videoUrl.replace('autoplay:', '')
      autoPlay = true
    }
    if (this.autoPlay) {
      autoPlay = this.autoPlay
    }
    const videoType = this.getFileType(this.videoUrl) || 'mp4'
    let otherParams = {}
    // alert(videoType)
    if (videoType === 'm3u8') {
    //   otherParams.html5 = {
    //     hls: {
    //       overrideNative: true
    //     },
    //     nativeAudioTracks: false,
    //     nativeVideoTracks: false
    //  }
      otherParams = {
        flash: {
          hls: { withCredentials: false }
        },
        html5: { hls: { withCredentials: false } },
        hls: true
      }
    }
    this.playerOptions = {
      muted: false, // 是否静音
      language: 'zh-CN',
      // aspectRatio: '16:9',
      playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
      controls: this.controls, // 底部控制器
      bigPlayButton: this.controls,
      controlBar: {
        fullscreenToggle: this.fullscreenToggle,
        timeDivider: true,
        durationDisplay: true,
        remainingTimeDisplay: false
      },
      preload: 'auto', // 视频预加载
      // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      autoplay: autoPlay,
      loop: this.loop,
      sources: [
        {
          type: videoMap[videoType],
          src: videoUrl
        }
      ],
      poster: this.poster, // 封面图
      notSupportedMessage: '此视频暂无法播放，请稍后再试',
      ...otherParams
      // his
    }
    // 手动给原生video设置poster封面
    if (this.$store.state.isBigScreen) {
      const dom = document.getElementsByTagName('video')
      if (dom.length) {
        console.log(this.poster)
        setTimeout(() => {
          dom[0].poster = this.poster
        }, 0)
      }
    }
  },
  methods: {
    // 监听播放
    onPlayerPlay(player) {
      // console.log('开始播放')
      // console.log('player play!', player)
    },
    // 监听暂停
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // 监听停止
    onPlayerEnded(player) {
      // this.setCurrentTime(0)
      if (this.loop) {
        player.reset()
      }
      // this.dealSources(this.videoUrl)
      // player.currentTime(0)
      // player.pause()
      this.$emit('playEnded')
    },
    // 监听加载完成
    onPlayerLoadeddata(player) {
      // console.log('player Loadeddata!', player)
    },
    // 监听视频缓存等待
    onPlayerWaiting(player) {
      // console.log('player Waiting!', player)
    },
    // 监听视频暂停后播放
    onPlayerPlaying(player) {
      // console.log('player Playing!', player)
    },
    // 监听视频播放时长更新
    onPlayerTimeupdate(player) {
      // console.log('player Timeupdate!', player, player.currentTime())
    },
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    // 监听状态改变
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // 监听播放器准备就绪
    playerReadied(player) {
      let videoUrl = this.videoUrl; let autoPlay = false
      console.log(videoUrl)
      if (this.videoUrl.indexOf('autoplay:') !== -1) {
        videoUrl = this.videoUrl.replace('autoplay:', '')
        autoPlay = true
      }
      if (this.autoPlay) {
        autoPlay = this.autoPlay
      }
      if (autoPlay) {
        this.playerPlay()
      }
      // console.log('example 01: the player is readied', player)
    },
    playerPlay() {
      console.log('开始播放')
      console.log(this.myVideoPlayer)
      this.myVideoPlayer.play()
    },
    playerPause() {
      console.log('停止播放')
      this.myVideoPlayer.pause()
      this.myVideoPlayer.currentTime(0)
      this.myVideoPlayer.cache_.currentTime = 0
    },
    getCurrentTime() {
      console.log(this.myVideoPlayer)
      return this.myVideoPlayer.cache_.currentTime || 0
    },
    getCache() {
      return this.myVideoPlayer.cache_
    },
    setCurrentTime(currentTime) {
      this.myVideoPlayer.currentTime(currentTime)
    },
    getFileType(fileName) {
      const exts = fileName.split('.')
      let ext = ''
      if (exts !== undefined) {
        if (exts.length <= 1 && fileName.indexOf('=') > -1) { // 直接输入上传到azure之后生成的文件地址
          return false
        } else {
          ext = exts[exts.length - 1]
          ext = ext.toLowerCase()
          return ext
        }
      } else {
        return false
      }
    },
    dealSources(newValue) {
      let videoUrl = newValue; let autoPlay = false
      if (this.videoUrl.indexOf('autoplay:') !== -1) {
        videoUrl = this.videoUrl.replace('autoplay:', '')
        autoPlay = true
      }
      if (this.autoPlay) {
        autoPlay = this.autoPlay
      }
      const videoType = this.getFileType(newValue) || 'mp4'
      this.playerOptions.sources[0].src = videoUrl
      this.playerOptions.sources[0].type = videoMap[videoType]
      this.playerOptions.autoPlay = autoPlay
      this.playerOptions.poster = this.poster
    }
  }
}
</script>

<style lang="less">
.videoHls {
  width: 100%;
  height: 100%;
}

.video-player-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .video-js {
    position: static;
  }

  .vjs-big-play-button {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .video-js {
    width: 100% !important;
    height: 100% !important;
    border-radius: 4px;

    .vjs-tech {
      border-radius: 4px;
    }
  }

  video {
    width: 100% !important;
    height: 100% !important;
    // object-fit: scale-down;
    object-fit: contain;
  }
}
</style>
