<template>
  <!-- <div @click="add(fileType.ImageVideo.value, gData.length)" class="m-visual-upload"> -->
  <div @click="add(fileType.ImageVideo.value)" class="m-visual-upload">
    <a-icon type="plus" style="font-size: 15px;" />
    <!-- <div class="u-text">
      支持添加多张图片/视频
    </div> -->
    <div class="u-text">
      支持添加图片/视频
    </div>
    <div class="u-text1">
      {{ sizeTipText }}
    </div>
  </div>
</template>

<script>
import { fileType } from '@/constants/fileType'
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    visualValue: {
      type: Object,
      default() {
        return {}
      }
    },
    visualKeys: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const list = []

    this.value.forEach((item, index) => {
      list.push({
        ...item,
        key: index,
        treeTitle: (item.title && item.title.value) ? item.title.value : ((item.text && item.text.value) ? item.text.value : `列表项-${index + 1}`)
      })
    })

    return {
      gData: list,
      fileType,
      checkedKeys: [],
      replaceFields: {
        title: 'treeTitle'
      }
    }
  },
  computed: {
    sizeTipText() {
      const screenProgram = this.$store.state.screenProgram
      let sizeTip = '建议上传1920*1080尺寸'
      if (screenProgram.width) {
        const { width, height } = screenProgram
        sizeTip = `建议上传${width}*${height}尺寸`
      }
      return sizeTip
    }
  },
  watch: {
    value(val = []) {
      const list = []

      val.forEach((item, index) => {
        list.push({
          ...item,
          key: index,
          treeTitle: (item.title && item.title.value) ? item.title.value : ((item.text && item.text.value) ? item.text.value : `列表项-${index + 1}`)
        })
      })

      this.gData = list
    }
  },
  methods: {
    add(type) {
      const componentData = this.$store.getters.getByVisualKeys(this.visualKeys).value
      if (componentData.length > 24) {
        this.$message.error('最多添加25个！')
        return
      }
      this.$emit('changeStatus', { type, isShow: true })
    }
  }
}
</script>

<style lang="postcss" scoped>
.m-visual-upload {
  width: 200px;
  height: 112px;
  background: #f2f3f5;
  border-radius: 4px;
  text-align: center;
  padding-top: 25px;

  .u-text {
    font-size: 12px;
    line-height: 17px;
    color: #333;
    margin-top: 11px;
  }

  .u-text1 {
    font-size: 12px;
    line-height: 17px;
    color: #9fa5ad;
    margin-top: 4px;
  }
}
</style>
