<template>
  <a-config-provider :locale="zhCN">
    <div class="g-doc">
      <Visual
        v-if="cmsVisual"
        :children="children"
        :mode="mode"
        :timestamp="timestamp"
        :query="query"
        :isPC="isPC"
      />
      <component
        v-else
        :is="plugItem.item"
        v-for="(plugItem, plugIndex) in children"
        :key="`component-${plugIndex}`"
        :reqData="plugItem.reqData"
        :mode="mode"
        :id="plugItem.id"
        :timestamp="timestamp"
        :query="query"
        :isPC="isPC"
      />
    </div>
  </a-config-provider>
</template>

<script>
import Visual from '~/components/common/Visual'
import { log } from '~/assets/js/util'
import { getFingerPrint } from '~/utils/logs'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

const formatProperties = (properties) => {
  if (!properties) {
    return null
  } else if (Array.isArray(properties)) {
    properties = properties.map(children => formatProperties(children))
  } else if (typeof properties === 'object' && properties !== null && properties._resolve === 'commonData') {
    properties = properties[properties._resolve_key].properties
  } else if (typeof properties === 'object' && properties !== null && properties._resolve !== 'commonData') {
    for (const key in properties) {
      if (Object.prototype.hasOwnProperty.call(properties, key)) {
        properties[key] = formatProperties(properties[key])
      }
    }
  }
  return properties
}
export default {
  layout({ query: { preview, h5 } }) {
    return (preview === 'true' && h5 !== 'true') ? 'visual' : 'default'
  },
  components: {
    Visual
  },
  data() {
    return {
      zhCN,
      plugs: [],
      mode: '',
      timestamp: 0,
      winstonLog: null
    }
  },
  head() {
    return {
      title: this.title,
      meta: [
        { hid: 'baidu-site-verification', name: 'baidu-site-verification', content: this.site ? (this.site.seoCode ? JSON.parse(this.site.seoCode).baiduSiteVerification : '') : '' },
        { hid: 'description', name: 'description', content: this.description },
        { hid: 'keywords', name: 'keywords', content: this.keywords },
        { name: 'viewport', content: this.isPC ? '' : 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.favicon || '/favicon_new.ico' }],
      script: this.isPC ? [] : [{
        src: '/rem.js'
      }]
    }
  },
  computed: {
    children() {
      let list

      if (this.mode === 'dev') {
        list = this.plugs
      } else {
        list = this.$store.state.components
      }

      return list.map((obj) => {
        return {
          ...obj,
          item: () => import(`@/components/${obj.key}/index`)
        }
      }).filter((value) => {
        return value.key
      })
    }
  },
  asyncData({ $axios, query, route, error, store, req, $ua, $winstonLog }) {
    const nowTimestamp = new Date().getTime()

    store.commit('changeCmsVisual', !!query.preview)

    const host = req.headers.host
    const siteName = host.split(':')[0]
    let siteId = ''
    let preview = false

    if (host.indexOf('preview') === 0) {
      // 预览模式
      preview = true
      siteId = host.split('.')[0].split('-')[1]
    } else {
      siteId = query.siteId
    }

    // 有siteId以siteId优先
    if (siteId) {
      store.commit('updateSiteId', siteId)
    }

    store.commit('updateSiteName', siteName)

    if (query.components) {
      const isH5 = false
      return {
        mode: 'dev',
        plugs: query.components ? query.components.split(',').map((item, index) => {
          return {
            key: item,
            id: item
          }
        }) : [],
        timestamp: nowTimestamp,
        cmsVisual: !!query.preview,
        query: query,
        isPC: query.h5 !== 'true' && (!isH5 || $ua.isFromPc())
      }
    } else {
      // 异步获取数据
      console.log('query', query)
      store.commit('updateProgramId', query.programId)
      const visualUrl = `/cms/url/page/preview?${query.programId ? `&programId=${query.programId}&` : ''}url=${encodeURIComponent(route.path)}&preview=true`
      const cmsUrl = `/cms/url/page/get?${query.programId ? `&programId=${query.programId}&` : ''}url=${encodeURIComponent(route.path)}`
      log(`get page data url: ${visualUrl}`)
      return $axios.$get((query.preview || preview) ? visualUrl : cmsUrl).then((res) => {
        log(`get page data res: ${{ ...res }}`)
        try {
          store.commit('updateHasScreensaver', res.hasScreensaver)
          store.commit('updateIsBigScreen', res.type === 'BIG_SCREEN')
          if (res.type === 'BIG_SCREEN') {
            store.commit('updateScreenParentUrl', res.program.parentUrl)
            store.commit('updateScreenProgram', res.program)
          }
          if (!res) {
            error({ statusCode: 404, message: 'Page not found' })
            if (process.server) {
              $winstonLog.info(`statusCode: 404; pageUrl: ${host}; requestUrl: ${cmsUrl}; preview: ${query.preview || preview}; query: ${JSON.stringify(query)}`)
            }
            return
          }

          const plugs = []

          store.commit('updatePageKey', res.key)

          if (res.body) {
            // 页面
            res.body.forEach((key) => {
              const item = res.components[key]

              if (item.type === 'Header' && res.properties && res.properties.key) {
                Object.assign(item.properties, {
                  selectKey: res.properties.key
                })
              }

              plugs.push({
                key: item.type,
                reqData: formatProperties(item.properties || item),
                id: key
              })
            })
          } else if (res.type && JSON.stringify(formatProperties(res.properties || res)) !== '{}') {
            plugs.push({
              key: res.type,
              reqData: formatProperties(res.properties || res),
              id: res.key
            })
          }

          store.commit('updateComponents', plugs)
          store.commit('updateConfigs', require.context('../components/', true, /\.json$/))
          const components = []
          store.state.configs.keys().forEach((key) => {
            const item = store.state.configs(key)
            Object.keys(item).forEach((contentKey) => {
              const content = item[contentKey]
              components.push(content)
            })
          })
          const judge = []
          plugs.forEach((item) => {
            components.forEach((item1) => {
              if (item.key === item1.type) {
                judge.push(item1.h5)
              }
            })
          })
          const isH5 = judge.every((item) => {
            return item === true
          })

          if (res.site && res.site.theme) {
            store.commit('updateTheme', res.site.theme)
          }

          return {
            plugs: plugs,
            timestamp: nowTimestamp,
            ...(res.properties || res),
            cmsVisual: !!query.preview,
            favicon: res.favicon,
            site: res.site,
            title: (query.preview || preview) ? '易千面-网易数创产业平台' : (res.properties || res).title,
            query: query,
            isPC: query.h5 !== 'true' && (!isH5 || $ua.isFromPc())
          }
        } catch (error) {
          if (process.server) {
            $winstonLog.error(`asyncData error: ${error}`)
          }
        }
      }).catch((e) => {
        const errorStr = `statusCode=${e.response.status || ''} message=${e.response.data.code === 'cms.error.site_closed' ? '站点已被封禁' : e.response.data.message}`
        if (process.server) {
          $winstonLog.info(`request cms error: ${cmsUrl}
          ${errorStr}`)
        }
        log(`request cms error: ${errorStr}`)
        // console.log(e)
        error({ statusCode: e.response.status, message: e.response.data.code === 'cms.error.site_closed' ? '站点已被封禁' : e.response.data.message })
      })
    }
  },
  beforeCreate() {
    this.$store.commit('updateConfigs', require.context('../components/', true, /\.json$/))
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      const isHidden = document.hidden
      if (isHidden) {
        this.$store.commit('updateIsShowScreenSaver', false)
        this.$store.commit('updateIsReCallScreenSaver', false)
        const videoList = document.querySelectorAll('video')
        const audioList = document.querySelectorAll('audio')
        videoList.forEach((item, index) => {
          item.pause()
        })
        this.$store.commit('updateIsStopAudio', null)
        audioList.forEach((item, index) => {
          item.pause()
          this.$store.commit('updateIsStopAudio', true)
        })
      } else {
        this.$store.commit('updateIsReCallScreenSaver', true)
      }
    })
    if (location.href.indexOf('preview=true') === -1) {
      this.logData()
    } else {
      this.getDomain()
    }
  },
  methods: {
    getDomain() {
      this.$axios.get('/domain').then((res) => {
        this.$store.commit('updateDomainData', res.data)
      })
    },
    logData() {
      const fingerPrint = getFingerPrint()
      const params = {
        accessDevice: fingerPrint,
        url: location.pathname,
        siteName: this.$store.state.siteName || '',
        siteId: ''
      }
      this.$axios.get('/cms/url/page/record', { params })
    }
  },
  errorCaptured(_err, _vm, info) {
    if (info === 'render') {
      console.log('这是一个组件渲染的错误')
    } else {
      console.log('这是一个事件处理的错误')
    }
    if (!process.server) {
      this.$throw(_err, _vm, info)
    }
    let flag = false
    if (process.env.NODE_ENV === 'development') {
      flag = true
    }
    // 阻止错误继续向外传播
    return flag
  }
}
</script>
