<template>
  <a-modal
    :visible="isShow"
    :maskClosable="false"
    :destroyOnClose="true"
    :confirmLoading="confirmLoading"
    @cancel="() => { $emit('cancel') }"
    @ok="confirmClip"
    title="图片裁剪"
    width="700px"
  >
    <div class="clipimage-box">
      <div class="clipimage-box_right">
        <img ref="cropper" :src="clipImage" class="right-image" alt="">
      </div>
    </div>
  </a-modal>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { getTransforms } from '@/utils/cliputils'

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default() {
        return {
          url: ''
        }
      }
    }
  },
  data() {
    return {
      cropper: null,
      cropData: null,
      clipType: 'NaN',
      afterImg: null,
      afterClipData: null,
      showTest: false,
      temStyle: null,
      confirmLoading: false
    }
  },
  computed: {
    clipImage() {
      let clipImage = this.info.url
      if (this.info.clipData) {
        clipImage = this.info.clipData.originUrl
      }
      return clipImage
    }
  },
  watch: {
    isShow(newVal) {
      if (newVal) {
        if (this.info.width) {
          this.clipType = this.info.width / this.info.height
        }
        setTimeout(() => {
          this.cropper = new Cropper(this.$refs.cropper, {
            preview: '.clip-preview',
            viewMode: 2, // 裁剪框不超出图片范围,图片缩放范围大于裁剪背景区域
            dragMode: 'move', // 裁剪框交互方式
            autoCropArea: 1, // 裁剪框初始大小
            aspectRatio: this.clipType,
            checkOrientation: false,
            guides: false,
            zoomable: false,
            ready: () => {
              this.info.url &&
              this.cropper.setData(this.info.url)
              if (this.info.clipData) {
                const clipData = this.info.clipData
                this.cropper.setCropBoxData({
                  left: clipData.left,
                  top: clipData.top,
                  width: clipData.width,
                  height: clipData.height
                })
              }
              this.cropData = this.cropper.getData()
            },
            cropmove: (v) => {
              this.cropData = this.cropper.getData()
            }
          })
        }, 500)
      }
    }
  },
  methods: {
    confirmClip1() {
      this.afterClipData = this.cropper.getCropBoxData()
      const imageData = this.cropper.getImageData()
      const canvasData = this.cropper.getCanvasData()
      const cropBoxData = this.cropper.getCropBoxData()
      const left = cropBoxData.left - canvasData.left - imageData.left
      const top = cropBoxData.top - canvasData.top - imageData.top
      let ratio = 1
      const el = document.querySelector('#hello')
      const originalWidth = el.offsetWidth
      const originalHeight = el.offsetHeight
      let newWidth = originalWidth
      let newHeight = originalHeight
      if (cropBoxData.width) {
        ratio = originalWidth / cropBoxData.width
        newHeight = cropBoxData.height * ratio
        console.log(ratio, newHeight, 'newHeight')
      }

      if (cropBoxData.height && newHeight > originalHeight) {
        ratio = originalHeight / cropBoxData.height
        newWidth = cropBoxData.width * ratio
        newHeight = originalHeight
      }
      console.log(newWidth, newHeight)
      const styles = getTransforms(Object.assign({
        translateX: -left * ratio,
        translateY: -top * ratio
      }, imageData))
      this.showTest = true
      this.temStyle = {
        display: 'block',
        'min-width': '0!important',
        'min-height': '0!important',
        'max-width': 'none!important',
        'max-height': 'none!important',
        'image-orientation': '0deg!important',
        width: imageData.width * ratio + 'px',
        height: imageData.height * ratio + 'px',
        ...styles
      }
      this.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const files = new window.File([blob], 'clipimage.png', { type: blob.type })
        console.log(files)
        const formdata = new FormData()
        formdata.append('file', files)
        await this.ImageUpload(formdata, this.afterClipData, files)
      })
      // this.$emit('ClipReturn', this.afterClipData)
    },
    confirmClip() {
      this.confirmLoading = true
      this.afterClipData = this.cropper.getCropBoxData()
      // const imageData = this.cropper.getImageData()
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        const blobTypes = blob.type.split('/')
        const type = blobTypes.length && blobTypes.length >= 1 ? blobTypes[1] : 'png'
        const fileName = `clipimage_${new Date().getTime()}.${type}`
        const files = new window.File([blob], fileName, { type: blob.type })
        this.ImageUpload(this.afterClipData, files)
      })
    },
    ImageUpload(afterClipData, files) {
     const uploader = this.$NosUploader({
        app: 'cms',
        code: process.env.UPLOAD_BIZ_CODE || 'creative-cms',
        securityCheck: false,
        singleton: true,
        onBeforeRequest: (url, params) => {
          params.custom = 'node'
          url = '/node' + url
          return { url, params }
        }
      })
      uploader.uploads([files], {}).then((res) => {
        const resultUrl = res[0].url
        afterClipData.imageUrl = resultUrl
        if (this.info.clipData && this.info.clipData.originUrl) {
          afterClipData.originUrl = this.info.clipData.originUrl
        } else {
          afterClipData.originUrl = this.info.url
        }
        this.confirmLoading = false
        this.$emit('ClipReturn', afterClipData)
      }).catch(() => {
        this.confirmLoading = false
      })
    }
  }
}
</script>

<style lang="less">
.clipimage-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &_right {
    .right-image {
      max-width: 550px;
      max-height: calc(100vh - 340px);
    }
  }
  .cropper-line {
    opacity: 0.75;
  }
  .line-e, .line-w {
    width: 3px;
  }
  .line-n, .line-s {
    height: 3px;
  }

  .cropper-point {
    width: 12px;
    height: 12px;
    background: #fff;
    border: 2px solid #3360FF;
    border-radius: 12px;
    opacity: 0.85;
  }
  .point-e, .point-n, .point-w, .point-s {
    border-radius: 0;
  }
  .point-e {
    margin-top: -6px;
    right: -6px;
  }
  .point-n {
    margin-left: -6px;
    top: -6px;
  }
  .point-w {
    left: -6px;
    margin-top: -6px;
  }
  .point-s {
    bottom: -6px;
    margin-left: -6px;
  }
  .point-ne {
    right: -6px;
    top: -6px;
  }
  .point-nw {
    left: -6px;
    top: -6px;
  }
  .point-sw {
    bottom: -6px;
    left: -6px;
  }
  .point-se {
    bottom: -6px;
    right: -6px;
  }
  .cropper-line {
    background: #3360FF;
  }
}
</style>
