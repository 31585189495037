function bin2hex(bin) {
  let i = 0
  const l = bin.length
  let chr
  let hex = ''
  for (i; i < l; ++i) {
    chr = bin.charCodeAt(i).toString(16)
    hex += chr.length < 2 ? '0' + chr : chr
  }
  return hex
}
export function getFingerPrint() {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const txt = 'https://di.163yun.com'
  ctx.textBaseline = 'top'
  ctx.font = "14px 'Arial'"
  ctx.fillStyle = '#0ff'
  ctx.fillRect(0, 0, 140, 50)
  ctx.fillStyle = '#00f'
  ctx.fillText(txt, 2, 15)
  ctx.fillStyle = 'rgba(102,204,0,0.7)'
  ctx.fillText(txt, 4, 17)

  const b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
  const bin = atob(b64)
  const crc = bin2hex(bin.slice(-16, -12))
  return crc
}
